import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import Abouttext from './subcomponents/Abouttext';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PhotoProvider,PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import React from 'react';
import '../styles/about.css'

const About = () => {
    const [showButton, setShowButton] = useState(true);
    const intl = useIntl()
    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 600) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };
    const Imag =(url,text) =>{
        return (
        <div className='example'>
            <div className='example-container'>
                <div className='example-imag'>
                    <img src={require(`../images/${url}`)} alt=""/>
                </div>
                <div className='imag-example-text'>{text}</div>
            </div>
        </div>)
    }
    const strongtext=(text)=>{
        return(
        <div className='abouttext-text'>
            <strong>{text}</strong>
        </div>)
    }
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    return (
        <div className='about-page'>
            <div className='main-header'>
                {showButton&&(
                    <div onClick={scrollToTop} className="back-to-top">
                        <a><ArrowUpwardOutlinedIcon sx={{fontSize:50,color:'white'}}/></a>
                    </div>
                )}
                <div className='main-header-head'>
                    {localStorage.getItem('locale')=='ru-RU'||localStorage.getItem('locale')==null?
                            (
                            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={require('../images/lang-en.png')} alt=""/>
                            </div>
                            ):(
                            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={require('../images/lang-ru.png')} alt=""/>
                        </div>)}
                    <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/news"><FormattedMessage id="main_link_news"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk>
                        <Lnk className='link' to="/main/#contact"><FormattedMessage id="main_link_contacts"/></Lnk>
                        <Lnk className='link' to="/documents"><FormattedMessage id="main_link_documents"/></Lnk>
                    </div>
                </div>
            </div>
            <div className='about-title'><FormattedMessage id="about_title"/></div>
            <div className='about-content'>
                <div className='about-content-title'><FormattedMessage id="about_company"/></div>
                <div className='about-content-text'>
                <Abouttext text={intl.formatMessage({id:'about_text_1'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_2'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_3'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_4'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_5'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_6'})}/>
                <div className='example'>
                    <div className='example-container'>
                        <div className='example-img'>
                            <PhotoProvider>
                                <PhotoView src={require('../images/A1.png')}>
                                    <img src={require('../images/A1.png')} alt="" />
                                </PhotoView>
                                <PhotoView src={require('../images/A2.png')}>
                                    <img src={require('../images/A2.png')} alt="" />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div className='example-text'><FormattedMessage id="about_text_img_1"/></div>
                    </div>
                </div>
                <Abouttext text={intl.formatMessage({id:'about_text_7'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_8'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_9'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_10'})}/>
                <Abouttext text='- С. П. Морозов, А. В. Владзимирский, Н. В. Ледихова, И. А. Соколина, Н. С. Кульберг, В. А. Гомболевский «Оценка диагностической точности системы скрининга туберкулеза легких на основе искусственного интеллекта» Туберкулёз и болезни лёгких, Том 96, № 8, 2018'/>
                <Abouttext text='- V.I.Klassen, A.A.Safin, A.V.Maltsev, N.G.Andrianov, S.P. Morozov, A.V.Vladzymyrskyy, N.V.Ledikhova, I.A Sokolina, N.S.Kulberg, V.A Gombolevsky, E.S.Kuzmina «AI-based screening of pulmonary tuberculosis: diagnostic accuracy» Journal of eHealth. Technology and Application, Volume 16, Number 1, November 2018, ISSN: 1881-4581'/>
                <Abouttext text={intl.formatMessage({id:'about_text_11'})}/>
                {Imag('A3.png',intl.formatMessage({id:'about_text_img_2'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_12'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_13'})}/>
                {Imag('A4.png',intl.formatMessage({id:'about_text_img_3'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_14'})}/>
                <Abouttext text='1. Свидетельство о государственной регистрации программы для ЭВМ 2017615663 «Программа автоматизированного анализа флюорографических изображений»;'/>
                <Abouttext text='2. Свидетельство о государственной регистрации программы для ЭВМ 2018615227 «Программа для ручной разметки патологий на флюорографических снимках»;'/>
                <Abouttext text='3. Свидетельство о государственной регистрации программы для ЭВМ 2018615226 «Программа автоматизированного анализа флюорографических изображений (версия 2)»;'/>
                <Abouttext text='4. Патент на изобретение 2684181 «Способ автоматизированного анализа ЦИФРОВЫХ флюорографических снимков»,'/>
                <Abouttext text='5. Свидетельство о государственной регистрации базы данных 2020620858 «Размеченные результаты флюорографии».'/>
                <Abouttext text={intl.formatMessage({id:'about_text_20'})}/>
                {Imag('A5.png',intl.formatMessage({id:'about_text_img_4'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_21'})}/>
                {Imag('A6.png',intl.formatMessage({id:'about_text_img_5'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_22'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_23'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_24'})}/>
                <div className='example'>
                    <div className='example-container'>
                        <div className='example-imag'>
                            <PhotoProvider>
                                <PhotoView src={require('../images/A7.png')}>
                                    <img src={require('../images/A7.png')} alt="" />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        <div className='imag-example-text'>{intl.formatMessage({id:'about_text_img_6'})}</div>
                    </div>
                </div>
                <div className='tech-text'><strong><FormattedMessage id="about_text_25"/></strong></div>
                {strongtext(intl.formatMessage({id:'about_text_26'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_27'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_28'})}/>
                {strongtext(intl.formatMessage({id:'about_text_29'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_30'})}/>
                {strongtext(intl.formatMessage({id:'about_text_31'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_32'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_33'})}/>
                {strongtext(intl.formatMessage({id:'about_text_34'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_35'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_36'})}/>
                {strongtext(intl.formatMessage({id:'about_text_37'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_38'})}/>
                {strongtext(intl.formatMessage({id:'about_text_39'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_40'})}/>
                {strongtext(intl.formatMessage({id:'about_text_41'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_42'})}/>
                {strongtext(intl.formatMessage({id:'about_text_43'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_44'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_45'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_46'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_47'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_48'})}/>
                {strongtext(intl.formatMessage({id:'about_text_49'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_50'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_51'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_52'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_53'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_54'})}/>
                {strongtext(intl.formatMessage({id:'about_text_55'}))}
                <Abouttext text={intl.formatMessage({id:'about_text_56'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_57'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_58'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_59'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_60'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_61'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_62'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_63'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_64'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_65'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_66'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_67'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_68'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_69'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_70'})}/>
                <Abouttext text={intl.formatMessage({id:'about_text_71'})}/>
                <Abouttext text=' '/>
                </div>
                {(document.documentElement.scrollWidth>500)?(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default About;