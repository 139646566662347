import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import React from 'react';
import Cookies from 'js-cookie'
import './protocol.css'
import { address, address_with_api } from '../settings';

const Protocol = () => {
    const [loading,setLoading] = useState(true)
    const [downloadTemplate,setDownloadTemplate] = useState(false)
    const [sending,setSending] = useState(false)
    const [users,setUsers] = useState([])
    const [file,Setfile]=useState("")
    const token = Cookies.get('_auth')
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        fetch(address_with_api+'/protocol/users', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer ' + token,
          },
          }).then(resp=>{
              if(resp.ok) return resp.json()
              else return false
          }).then(result=>{
              if(result!==false){
              setUsers(result)
              setLoading(false)
            }
              else alert("Неверно введённые данные")
          })
    },[])
    const handleChange =()=>{Setfile(document.getElementById("file").files[0].name)}
    const handle = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
    }
    const getClearProtocol = () =>{// Скачивание файла со статистикой по дням для выбранных пользователей
        setDownloadTemplate(true)
        var id_mas = Object.values(document.getElementsByName('radio')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        if(id_mas.length==0){alert('Выберите пользователя');setDownloadTemplate(false); return 0 }
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        fetch(address_with_api+'/download/clear/protocol', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:'{"id_mas":{"mas":['+id_mas[0]+']},"date_start":"'+moment(start).format("YYYY-MM-DD")+' 00:00:00","date_end":"'+moment(end).format("YYYY-MM-DD")+' 23:59:00"}'
        }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Протокол за период с ${moment(start).format('DD.MM.YYYY')} по ${moment(end).format('DD.MM.YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadTemplate(false)
            })
    }
    const sendProtocol = () => {
        setSending(true)
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        if(!document.getElementById('file').value){
            alert("Выберите файл");
            return 0
        }
        const fd = new FormData()
        fd.append('file',document.getElementById('file').files[0])
        fetch(address+'/download/protocol', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer '+token,
            },
            body:fd
        }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Протокол(${moment(start).format('DD.MM.YYYY')} - ${moment(end).format('DD.MM.YYYY')}).zip`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setDownloadTemplate(false)
                setSending(false)
                // Setfile("")
        })
    }
    return (
        <div className='admin-protocol'>
            <div className='admin-protocol-table'>
                <div className='admin-protocol-table-title'>
                    <div className='admin-protocol-table-title-text'>Выбор пользователя</div>
                </div>                
                <div className='admin-protocol-table-data'>
                    <div className='admin-protocol-list'>
                        <ul className='admin-protocol-ul'>
                            {loading?(
                                <div className='div-loading' id='editimage'>
                                    <CircularProgress sx={{color:'white'}}/>
                                </div>):(
                                users.map((item)=>{
                                    return(
                                        <li className='admin-protocol-li' key={item.id}>
                                            <div className='admin-protocol-li-div' onClick={()=>document.getElementById(item.id).checked=true}>
                                                <input type='radio' id={item.id} name='radio'/><label>{item.login+' '}({item.full_name})</label>
                                            </div>
                                        </li>
                                    )
                                }))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='admin-protocol-period'>
                <div className='ad-protocol-select'>
                    <select id='ad-select' onChange={()=>handle()}>
                        <option value='default'>Выбрать период</option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='last_week'>Прошлая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-protocol-date'>
                    <input className="admin-protocol-date-input" id = 'start' type="date"/>
                    <input className="admin-protocol-date-input" id = 'end' type="date"/>
                </div>
                <div className='admin-protocol-button'>
                    {downloadTemplate?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>):
                        (<button onClick={()=>getClearProtocol()}>Cформировать таблицу с результатами исследований</button>)}
                </div>
            </div>
            <div className='admin-protocol-sender'>
                <div className='admin-protocol-sender-title'>
                    <div className='admin-protocol-sender-title-text'>
                        Загрузить заполненную таблицу с заключениями врача
                    </div>
                </div>
                <div className='admin-protocol-sender-browse'>
                    <div className='admin-protocol-sender-browse'>
                        <input id="file" type="file" onChange={()=>handleChange()}/>
                        <label htmlFor="file">
                            {file==="" ? "Обзор":file}
                        </label>
                    </div>
                </div>
                <div className='admin-protocol-button'>
                    {sending?<button onClick={()=>sendProtocol()}><CircularProgress sx={{color:'white'}} size='small'/></button>:
                    <button onClick={()=>sendProtocol()}>Отправить файл</button>}
                </div>
            </div>
        </div>
    );
}

export default Protocol;