import React from 'react';
import '../styles/registration.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    let history = useNavigate()
    return (
        <div className='fail-page'>
            <CheckCircleIcon sx={{color:'#5dd986',fontSize:350}}/>
            <p className='fail-title'>Успешно</p><br/><p className='fail-text'>Ваш платёж принят в обработку</p>
            <div className='fail-button'><button onClick={()=>history(-1)}>Вернуться в кабинет</button></div>
        </div>
    );
}

export default Success;