import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';


export const IndividualSidebarData = [
  {
    title: "Онлайн обработка",
    path: "research",
    icon:<ImageSearchIcon fontSize="large" />
  },
  {
    title: "Статистика",
    path: "stat",
    icon:<QueryStatsIcon fontSize="large" />
  },
  {
    title: "Ошибочные исследования",
    path: "errors",
    icon:<ReportGmailerrorredIcon fontSize="large" />
  },
  {
    title: "Настройки аккаунта",
    path: "usermain",
    icon:<ManageAccountsIcon fontSize="large" />
  },
  {
    title: "Финансы",
    path: "finance",
    icon:<CurrencyRubleOutlinedIcon fontSize="large"/>
  },
];
export const enIndividualSidebarData = [
  {
    title: "On-line proccessing",
    path: "research",
    icon:<ImageSearchIcon fontSize="large" />
  },
  {
    title: "Statistic",
    path: "stat",
    icon:<QueryStatsIcon fontSize="large" />
  },
  {
    title: "Incorrect researches",
    path: "errors",
    icon:<ReportGmailerrorredIcon fontSize="large" />
  },
  {
    title: "Main",
    path: "usermain",
    icon:<ManageAccountsIcon fontSize="large" />
  },
  {
    title: "Finance",
    path: "finance",
    icon:<CurrencyRubleOutlinedIcon fontSize="large" sx={{color:'gold'}}/>
  },
];
