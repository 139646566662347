import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import {createContext, useEffect} from 'react';
import { AuthProvider } from 'react-auth-kit'
import {IntlProvider} from "react-intl";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { useState } from "react";
export const Context = createContext(null)

const  App = () => {
  const [locale,setLocale] = useState(LOCALES.RUSSIAN)
  useEffect(()=>{
    if(localStorage.getItem('locale')===null)localStorage.setItem('locale','ru-RU');
    else setLocale(localStorage.getItem('locale'));

  },[])
  return (
    <BrowserRouter>
      <AuthProvider authType = {'cookie'} authName={'_auth'} cookieDomain={window.location.hostname} cookieSecure={window.location.protocol === "https:"}>
        <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.RUSSIAN}>
          <AppRouter/>
        </IntlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
