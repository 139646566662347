import React from 'react';
import { Routes,Route } from 'react-router-dom';
import Research from '../User/Research';
import Stats from '../User/Stats';
import Usermain from '../User/Usermain';
import Navbar from './subcomponents/Navbar';
import Sidebar from './subcomponents/sidebar';
import {useState} from 'react';
import './subcomponents/sidebar.css'
import MenuIcon from '@mui/icons-material/Menu';
import UserErrors from '../User/UserErrors';


const User = () => {
    const [sidebar,Setsidebar]=useState(true)
    const change = () => {
        Setsidebar(true)
        if(sidebar)document.getElementById('sidebar').style.left="0px";
        if(document.documentElement.scrollWidth<1100)document.getElementById('sidebar').style.left="0px";
    }
    return (
        <div className='user-page'>
            <Sidebar/>
            <div className='sidebar-menu-btn'>
                <a onClick={()=>change()}><MenuIcon sx={{ fontSize: 30 }}/></a>
            </div>
            <div className='user-page-part'>
                <Navbar/>
                <div className='user-page-content'>
                    <Routes>
                        <Route path="usermain" element={<Usermain/>}/>
                        <Route path="stat" element={<Stats/>}/>
                        <Route path="research" element={<Research/>}/>
                        <Route path="errors" element={<UserErrors/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default User;