import CircularProgress from '@mui/material/CircularProgress';
import { useState,useEffect } from 'react';
import React from 'react';
import "./Adminorganization.css"
import Cookies from 'js-cookie'
import { address } from '../settings';

function Adminorganizations() {
    const token = Cookies.get('_auth')
    const [loading,setLoading] = useState(true)
    const [edit,setEdit]=useState(false)
    const [orgs,setOrgs] = useState([])
    const [organization,setOrganization]=useState({
        id:0,
        name:'',
        address:'',
        contract:'',
        price:'',
        itn:'',
        shortname:'',
    })
    useEffect(()=>{
        fetch(address+'/get/all/organizations', {
            method: 'POST',
            headers: {
              'accept': 'application/json',
              'Authorization': 'Bearer ' + token,
            }
        }).then(result=>result.json()).then(res=>{
            setOrgs(res)
            setLoading(false)
        })
    },[])
    const setdata = (data) => {
        setEdit(true)
        setOrganization({...organization,
            ['id']:data[0],
            ['name']:data[1],
            ['address']:data[2],
            ['contract']:data[3],
            ['price']:data[4],
            ['itn']:data[5],
            ['shortname']:data[6],
        })
    }
    const clearFrom = ()  =>{
        setOrganization({...organization,
            ['id']:null,
            ['name']:'',
            ['address']:'',
            ['contract']:'',
            ['price']:'',
            ['itn']:'',
            ['shortname']:''
        })
        setEdit(false)
    }
    const text = (text) => {
        return (
            <div className='admin-users-form-input-text-block'>
                {text}
            </div>
        )
    }
    const handle = (e) => {
        setOrganization({
          ...organization,
          [e.target.name]: e.target.value,
        });
    }
    const updateOrganization = () =>{
        let obj={
            "org_id":organization.id,
            "organization":organization.name,
            "address":organization.address,
            "contract":organization.contract,
            "price":organization.price,
            "itn": organization.itn,
            "full_name":organization.shortname
        }
        let body = JSON.stringify(obj)
        setLoading(true)
        fetch(address+'/update/organization', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:body
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setOrgs(res)
                setLoading(false)
            }
        })
        setOrganization({...organization,
            ['id']:null,
            ['name']:'',
            ['address']:'',
            ['contract']:'',
            ['price']:'',
            ['itn']:'',
            ['shortname']:''
        })
        setEdit(false)    
    }
    const registerOrganization = () =>{
        let obj={
            "organization":organization.name,
            "address":organization.address,
            "contract":organization.contract,
            "price":organization.price,
            "itn": organization.itn,
            "research_size":0,
            "full_name":organization.shortname,
            "email": "mail@mail.ru",
            "contract_number": "0",
            "contract_date": "0",
            "tarif_type": "free",
            "organization_full": organization.name
        }
        let body = JSON.stringify(obj)
        setLoading(true)
        fetch(address+'/register/organization', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:body
        }).then(response=>{if(response.ok) return response.json();else return false}).
        then(res=>{
            if(res!=false){
                setOrgs(res)
                setLoading(false)
            }
        })
        setOrganization({...organization,
            ['id']:null,
            ['name']:'',
            ['address']:'',
            ['contract']:'',
            ['price']:'',
            ['itn']:'',
            ['shortname']:''
        })
        setEdit(false)    
    }
    return (
        <div className='admin-organizations'>
            <div className='admin-organizations-table'>
                <div className='admin-organizations-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <table className='admin-organization-table'>
                            <thead className='ad-user-thead'>
                                <tr className='ad-user-tr'>
                                    <th className='ad-user-th'>Название</th>
                                    <th className='ad-user-th'>Адрес</th>
                                    <th className='ad-user-th'>Договор</th>
                                    <th className='ad-user-th'>Цена исследования</th>
                                    <th className='ad-user-th'>ИНН</th>
                                    <th className='ad-user-th'>Действия</th>
                                </tr>
                            </thead>
                            <tbody className='ad-user-tbody'>
                                {orgs.map((item)=>
                                    <tr className='ad-user-tr' key={item.id}>
                                        <td className='ad-user-td'>{item.organization}</td>
                                        <td className='ad-user-td'>{item.address}</td>
                                        <td className='ad-user-td'>{item.contract}</td>
                                        <td className='ad-user-td'>{item.price}</td>
                                        <td className='ad-user-td'>{item.itn}</td>
                                        <td className='ad-user-td-btn'><button className='ad-user-edit' onClick={()=>setdata([item.id,item.organization,item.address,item.contract,item.price,item.itn,item.full_name])}>Редактировать</button></td>
                                    </tr>
                                )}  
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
            <div className='admin-users-form'>
                <div className='admin-users-form-title'>
                    Создание/редактирование организации
                </div>
                <div className='admin-users-form-input'>
                    <div className='admin-users-form-input-text'>
                        {text("Наименование")}
                        {text("Адрес")} 
                        {text("Договор")} 
                        {text("Цена за исследование")}
                        {text("ИНН")}
                        {text("Название для статистики")}
                    </div>
                    <div className='admin-users-form-input-field'>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="name" id='login' value={organization.name} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="address" id='login' value={organization.address} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="contract" id='login' value={organization.contract} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="price" value={organization.price} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="itn" value={organization.itn} onChange={handle}/>
                        </div>
                        <div className='admin-users-form-input-text-block'>
                            <input type="text" name="shortname" value={organization.shortname} onChange={handle}/>
                        </div>
                    </div>
                </div>
                {edit?(<div className='admin-users-form-button'>
                    <button className='clear-form-btn' onClick={()=>clearFrom()}>Очистить форму</button>
                    <button onClick={()=>updateOrganization()}>Сохранить</button>
                </div>):
                (<div className='admin-users-form-button'>
                    <button className='clear-form-btn' onClick={()=>clearFrom()}>Очистить форму</button>
                    <button onClick={()=>registerOrganization()}>Создать</button>
                </div>)}
            </div>
        </div>
    );
}

export default Adminorganizations;