import React from 'react';
import './test.css'
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';


const Test = (props) => {
    const path = '/test/' + props.identif
    return (
        <div className='test-form' id={props.id}>
            <div className='test-img'>
                <img src={require(`./img/${props.image}`)} alt=""/>
            </div>
            <div className='test-text'>{props.text}</div>
            <Link className='test-button' to={path}><button><FormattedMessage id="main_test_btn"/></button></Link>
        </div>
    );
};

export default Test;