import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect,useState } from 'react';
import {FormattedMessage,useIntl} from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { CategoryScale } from 'chart.js';
import PrismaZoom from 'react-prismazoom'
import { Bar,Pie } from 'react-chartjs-2'
import SearchIcon from '@mui/icons-material/Search';
import Chart from 'chart.js/auto';
import { Pagination } from '@mui/material';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/ru';
import './stats.css'
import { address, address_with_api } from '../settings';
import UserTable from './UserTable';

Chart.register(CategoryScale);

const Stats = () => {
    const [detalizationLoading,setDetalizationLoading] = useState(true)
    const [activeDetalization,setActiveDetalization] = useState(false)
    const [detalizationShown,setDetalizationShown] = useState(false)
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [downloadState,setDownloadState] = useState(false)
    const [researchBackup,setResearchBackup] = useState([])
    const [imageLoading,setImageLoading] = useState(false)
    const [tableLoading,setTableLoading] = useState(true)
    const [pathologies,setPathologies] = useState({})
    const [detailCount,setDetailCount] = useState(0)
    const [researches,setResearches] = useState([])
    const [normCount,setNormCount] = useState(0)
    const [elements,setElements] = useState(100)
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [loading,setLoading] = useState(true)
    const [average, setAverage] = useState(0)
    const [counter,setCounter] = useState(0)
    const [podMas,setPodMas]=useState([])
    const [price,setPrice] = useState(0)
    const [pages,setPages] =useState(1)
    const token = Cookies.get('_auth')
    const [page,setPage]=useState(1)
    const [url,setUrl]=useState('')
    const intl = useIntl()
    const [imageId,setImageId] = useState(0)
    const [commentText,setCommentText] = useState('')
    const [comments,setComments] = useState()
    const [showCommentWindow,setShowCommentWindow] = useState(false)
    const [radioValue,setRadioValue] = useState(-1)
    const [rate,setRate] = useState(-1)
    const [radioSubValue,setRadioSubValue] = useState(-1)
    const [userRate,setuserRate] = useState('-1')
    const [commentsLoading,setCommentsLoading] = useState(true)
    const [itemMode,setItemMode] = useState(0)
    const [errors,setErrors] = useState()
    const [showComments,setShowComments] = useState(false)
    const [userError,setUserError] = useState({
        "FP":"0",
        "FN":"0",
        "WL":"0",
        "WC":"0",
        "classes":"0"
    })
    const [sort,setSort] = useState({
        name:'datetime',
        direction:false  //True = на возрастание; False = на убывание 
    })
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let start = moment().date(1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        let end = moment().format('YYYY-MM-DD HH:mm:ss')
        let body={
            "id_mas":{"mas":[104]},
            "mode":"general",
            "date_start":start,
            "date_end":end,
            "elements":100
        }
        fetch(address+'/get/users/statistic', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
                setKeyArray(Object.keys(resp[0]['World']))
                setValArray(Object.values(resp[0]['World']))
                setCounter(resp[1])
                setCounter(resp[1])
                setLoading(false)
          })
        setLoading(true)
        fetch(address+'/get/users/researches', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
              },
            body:JSON.stringify(body)})
              .then(resp=>{if(resp.ok)return resp.json()})
                  .then(result=>{
                        setResearchBackup(result)
                        setPages(1)
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/elements); i++){
                            podmas.push(result.slice((i*elements), (i*elements) + elements));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                  })
    },[])
    const handleResearches = () =>{
        let masiv = []
        masiv = [...researchBackup].filter((item)=>item.studyinstanceuid.indexOf(document.getElementById('research2').value)!=-1||item.patientid.indexOf(document.getElementById('research2').value)!=-1)
        setResearches(masiv)
    }
    const downloadFile =() => {//метод для скачивания файла с сервера
        setDetalizationShown(true)
        setDetalizationLoading(true)
        let body ={
            "id_mas":{"mas":[0]},
            "date_start":moment(document.getElementById('start').value).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            "date_end":moment(document.getElementById('end').value).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
        }
        fetch(address_with_api+'/get/researches', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(res=>{if(res.ok) return res.json()}).then(result=>{
                let reg = /[А-Яа-яA-Za-z\.,/\s]/gi
                setDetailCount(result.length)
                var classes = result.map((item)=>item.classes)
                let str = JSON.stringify(classes).match(reg).join('').split(',').map((item)=>item.trim())
                let object={}
                str.forEach(function (x) { object[x] = (object[x] || 0) + 1; });
                setNormCount(object[""])
                delete object[""]
                const ordered = Object.keys(object).sort().reduce(
                    (obj, key) => { 
                        obj[key] = object[key]; 
                        return obj;
                    }, 
                    {}
                );
                setPathologies(ordered)
                setDetalizationLoading(false)
            })
    }
    const handlePage = (event, value) => {
        setPage(value);
        setResearches(podMas[value-1])
    }
    const nextPage =() => {
        setTableLoading(true)
        let number = parseInt(document.getElementById("elements").value)
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        setTableLoading(true)
        let body={
            "id_mas":{"mas":[0]},
            "mode":"modif",
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":(number*(page+1))
        }
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <result.length/number; i++){
                            podmas[i]=result.slice((i*number), (i*number) + number);
                        }
                        setPage(podmas.length)
                        setPodMas(podmas)
                        setResearches(podmas[podmas.length-1])
                        setTableLoading(false)
                    })
    }
    const setPeriod = () => {// Отправка даты начала и конца периода для получения исследований
        if(!moment(document.getElementById('start').value).isValid()){alert('Указана несуществующая дата начала периода');return 1;}
        if(!moment(document.getElementById('end').value).isValid()){alert('Указана несуществующая дата конца периода');return 1;}
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        if(document.getElementById('start').value>document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        setLoading(true)
        setTableLoading(true)
        let body={
            "id_mas":{"mas":[0]},
            "mode":'modif',
            "date_start":moment(document.getElementById('start').value).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            "date_end":moment(document.getElementById('end').value).endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            "elements":elements
        }
        fetch(address+'/get/users/statistic', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
                setKeyArray(Object.keys(resp[0]['World']))
                setValArray(Object.values(resp[0]['World']))
                setCounter(resp[1])
                setPage(1)
                setCounter(resp[1])
                setLoading(false)
          })
          fetch(address+'/get/users/researches', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
              },
            body:JSON.stringify(body)})
              .then(resp=>{if(resp.ok)return resp.json()})
                  .then(result=>{
                        setResearchBackup(result)
                        result.length!==0?setAverage(7):setAverage(0)
                        setPages(1)
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/elements); i++){
                            podmas.push(result.slice((i*elements), (i*elements) + elements));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                  })
    }
    const str = (txt) => { //Отображение текста строк
        return (
            <div className='info-txt'>
                {txt}
            </div>)
    }
    const handleSelect = () => {//Обработчик изменения select компонента
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('select').value='previous_quarter'
        else document.getElementById('select').value='random'
    }
    const closeImgEditor  = () => {
        setShowComments(false)
        setRate(-1)
        setRadioValue(-1)
        setRadioSubValue(-1)
        setIsImgEditorShown(false);
    };//Закрытие/открытие изображения
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        setPeriod()
    }
    const handleElements =() => {
        setTableLoading(true)
        let number = parseInt(document.getElementById("elements").value)
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":[0]},
            "mode":'modif',
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":number*page
        }
        fetch(address+'/get/users/researches', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/number); i++){
                            podmas.push(result.slice((i*number), (i*number) + number));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                        setPage(1)
                    })
    }
    const getImage = (id) => {
        setImageId(id)
        setCommentsLoading(true)
        setShowComments(false)
        setShowCommentWindow(false)
        setIsImgEditorShown(true)
        setImageLoading(true)
        let mas=[]
        let body={
            "images_id":[id],
        }
        fetch(address+'/get/users/comments', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(response=>response.json()).then(result=>{
            let comment_mas = Object.values(result)[0].comment
            if(comment_mas.length==1 && comment_mas[0]==''){
                setComments(['Нет'])
            }
            else{
                setComments(comment_mas)
            }
            setUserError({
                "FP":Object.values(result)[0]["FP"],
                "FN":Object.values(result)[0]["FN"],
                "WC":Object.values(result)[0]["WC"],
                "WL":Object.values(result)[0]["WL"],
                "classes":Object.values(result)[0]["classes"],
            })
            if(Object.values(result)[0]["FP"]==1)mas.push("ложноположительный результат")
            if(Object.values(result)[0]["FN"]==1)mas.push("ложноотрицательный результат")
            if(Object.values(result)[0]["WL"]==1)mas.push("неверное оконтуривание")
            if(Object.values(result)[0]["WC"]==1)mas.push("неверная классификация патологий")
            setErrors(mas)
            if(Object.values(result)[0].user_rate =='1') setuserRate(1)
            else if (Object.values(result)[0].user_rate =='0') setuserRate(0)
            else setuserRate(-1)
            setCommentsLoading(false)
        })
        setShowComments(false)
        document.getElementById(id).style.backgroundColor='lightskyblue'
        fetch(address_with_api+'/get/research/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else { 
                    setImageLoading(false);
                    console.log('что-то пошло не так')
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    const closeTableDetalization = () =>{
        setActiveDetalization(false)
        setDetalizationShown(false)
    }
    const sortTable = (field) =>{
        let masiv = []
        if(sort.name===field){
            if (sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
                setSort({...sort,['name']:field,['direction']:false})
                setResearches(masiv)
            }
            if(!sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(a['datetime'])-moment(b['datetime']))
                if(field=='prob')masiv = [...researches].sort((a,b)=>a['prob']-b['prob'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>b['studyinstanceuid'].localeCompare(a['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>b['organization'].localeCompare(a['organization']))
                if(field=='classes')masiv = [...researches].sort((a,b)=>b['classes'].localeCompare(a['classes']))
                setResearches(masiv)
                setSort({...sort,['name']:field,['direction']:true})
            }
        }
        else{
            if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
            if(field=='prob')masiv = [...researches].sort((a,b)=>b['prob']-a['prob'])
            if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
            if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
            if(field=='classes')masiv = [...researches].sort((a,b)=>a['classes'].localeCompare(b['classes']))
            setSort({...sort,['name']:field,['direction']:false})
            setResearches(masiv)
        }
    }
    const sendComment = () => {
        if(commentText.length==0){
            window.alert('Нельзя отправить пустой комментарий')
            return 0
        }
        let new_comments = [...comments, commentText]
        let body ={
            images_id: imageId,
            comments: new_comments
        }
        fetch(address+'/users/comments/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(response=>response.status).then(result=>{
            if(result==200){
                if(comments[0]=='Нет'&&comments.length==1){
                    setComments([commentText])
                }
                else if(comments[0]=='Нет'&&comments.length>1){
                    new_comments.shift()
                    setComments(new_comments)
                }
                else{
                    setComments(new_comments)
                }
                setCommentText('')
                setShowCommentWindow(false)
            }
          })
    }
    const sendRate = () =>{
        // if(radioValue===-1){alert('Выберите один из вариантов ошибки');return 0}
        if(radioSubValue===-1&&!document.getElementById('detectradio5')){alert('Выберите подпункт');return 0}
        let checkboxes = document.getElementsByName('detectcheckbox')
        let body={
            "images_id":imageId,
            "error":{
                "Rate":rate,
                "FP":0,
                "FN":0,
                "WL":0,
                "WC":0,
            }
        }
        let mas=[]
        for(var i=0; i<checkboxes.length; i++){
            if(checkboxes[i].checked){
                body["error"][checkboxes[i].value]=1
                if(checkboxes[i].value=='FP')mas.push("ложноположительный результат")
                if(checkboxes[i].value=='FN')mas.push("ложноотрицательный результат")
                if(checkboxes[i].value=='WL')mas.push("неверное оконтуривание")
                if(checkboxes[i].value=='WC')mas.push("неверная классификация патологий")
            }
        }
        setErrors(mas)
        // if(radioValue==1&&body["WL"]==0&&body["WC"]==0){alert('Выберите подпункт');return 0}
        // try{
        //     body["error"][document.querySelector('input[name="detectradio"]:checked').value]=1;
        // }
        // catch{
        // }
        fetch(address+'/users/comments/error/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
        }).then(response=>response.status).then(result=>{if(result==200){
            setRate(0)
            setUserError({...userError,['FN']:body['error']['FN'],['FP']:body['error']['FP'],['WL']:body['error']['WL'],['WC']:body['error']["WC"]})
            mas.push()
            setuserRate(0)
            setRate(-1)
        }})
        if(commentText.length!==0){
            let new_comments = [...comments, commentText]
            let body ={
                images_id: imageId,
                comments: new_comments
            }
            fetch(address+'/users/comments/update', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
                body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{
                if(result==200){
                    if(comments[0]=='Нет'&&comments.length==1){
                        setComments([commentText])
                    }
                    else if(comments[0]=='Нет'&&comments.length>1){
                        new_comments.shift()
                        setComments(new_comments)
                    }
                    else{
                        setComments(new_comments)
                    }
                    setCommentText('')
                    setShowCommentWindow(false)
                    setuserRate(0)
                }
            })
        }
    }
    const sendAgreement = () => {
        let body={
            "images_id":imageId,
            "error":{
                "Rate":rate,
                "FP":0,
                "FN":0,
                "WL":0,
                "WC":0,
            }
        }
        fetch(address+'/users/comments/error/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{if(result==200){
                setRate(-1)
                setCommentText('')
                setShowCommentWindow(false)
                setuserRate(1)
            }})
        if(commentText.length!==0){
            let new_comments = [...comments, commentText]
            let body ={
                images_id: imageId,
                comments: new_comments
            }
            fetch(address+'/users/comments/update', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
                body:JSON.stringify(body)
            }).then(response=>response.status).then(result=>{
                if(result==200){
                    if(comments[0]=='Нет'&&comments.length==1){
                        setComments([commentText])
                    }
                    else if(comments[0]=='Нет'&&comments.length>1){
                        new_comments.shift()
                        setComments(new_comments)
                    }
                    else{
                        setComments(new_comments)
                    }
                    setCommentText('')
                    setShowCommentWindow(false)
                    setuserRate(1)
                }
            })
        }
    }
    const handleComment = (e) => {
        setCommentText(e.target.value)
    }
    return (
        <div className='stat-main'>
            {detalizationShown&&
                <div className={activeDetalization?'detalization-active':'detalization'}>
                    <div className='detalization-header'>
                        <div className='detalization-header-info'>
                            <FormattedMessage id="detalization_from_to"/> {moment(document.getElementById('start').value).format("DD.MM.YYYY")} - {moment(document.getElementById('end').value).format("DD.MM.YYYY")}
                        </div>
                        <div className='datalization-header-active'>
                            {activeDetalization?
                                (<button id='researchTableBtn' onClick={()=>setActiveDetalization(false)}><FormattedMessage id="detalization_collapse"/></button>):
                                (<button id='researchTableBtn' onClick={()=>setActiveDetalization(true)}><FormattedMessage id="detalization_expand"/></button>)
                            }
                            <button onClick={()=>closeTableDetalization()}><FormattedMessage id="detalization_close"/></button>
                        </div>
                    </div>
                    {detalizationLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='detalization-body'>
                            <div className='detalization-body-text'>
                                <div className='detalization-text'><FormattedMessage id="detalization_count"/> {detailCount}</div>
                                <div className='detalization-text'><FormattedMessage id="detalization_norm"/>: {((normCount/detailCount).toFixed(3)*100).toFixed(1)}% <FormattedMessage id="detalization_pathologies"/>: {((1-(normCount/detailCount).toFixed(3)).toFixed(3)*100).toFixed(1)}%</div>
                            </div>
                            <div className='detalization-body-diagram'>
                                <Pie style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                    labels:[intl.formatMessage({id:'detalization_norm'}),intl.formatMessage({id:'detalization_pathologies'})],
                                    datasets:[
                                        {
                                            data:[normCount,(detailCount-normCount)],
                                            backgroundColor: [
                                                "#63FF84",
                                                "#FF6384",
                                                ],
                                            borderWidth: 1,
                                            borderColor:'grey'
                                        },
                                    ]
                                }}
                                options={{
                                }}/>
                            </div>
                            <div className='pathologies-text'>
                                <div className='pathologies-text-title'>
                                    <FormattedMessage id="detalization_distribution"/>
                                </div>                            
                            </div>
                            <div className='pathologies-diagram'>
                                <Bar style={{maxHeight:"100%",maxWidth:"100%",fontColor:'black', background:'white',borderRadius:10,padding:10,width:'auto',height:'auto'}} data={{
                                        labels:Object.keys(pathologies),
                                        label:'yfpdfybt',
                                        datasets:[
                                            {
                                                label:'',
                                                data:Object.values(pathologies),
                                                backgroundColor: [
                                                    '#7EEB46',
                                                    '#F2BD3A',
                                                    '#DB4A40',
                                                    '#7D3AF2',
                                                    '#38D8E8',
                                                    '#DB4ACC',
                                                    '#4C6FF5',
                                                    '#6AB7F5',
                                                    '#C3EB9B',
                                                    '#EB557F'
                                                ],
                                                borderWidth: 1,
                                                borderColor:'grey'
                                            },
                                        ]
                                    }} options={{
                                        interaction: {
                                            intersect: false,
                                            index:true
                                        },
                                        plugins: {
                                            legend: {
                                                title:"Распределение по патологяим",
                                                display: false,
                                                labels: {
                                                    display: false,
                                                    title:"Распределение по патологяим",
                                                }
                                            },
                                        }
                                        }}/>
                            </div>
                        </div>
                    )}
                </div>
            }
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow'>
                    <div className='admin-edit-header'>
                        <div className='comments'>
                        <button onClick={()=>{setShowComments(!showComments)}}>Коментарии</button>
                            {showComments&&<div className='comments-list'>
                                {comments.map((item,index)=>(<p key={index}>{item}</p>))}
                                <div className='comments-button'>
                                    <button onClick={()=>{setShowCommentWindow(!showCommentWindow);setRate(-1)}}>Добавить комментарий</button>
                                </div>
                            </div>}
                            {showCommentWindow&&
                            <div className='comment-window'>
                                <div className='comment-window-input'>
                                    <textarea rows="1" placeholder="Введите комментарий" value={commentText} onChange={handleComment}/>
                                </div>
                                <div className='comments-window-button'>
                                    <button onClick = {()=>sendComment()}> Отправить комментарий </button>
                                    <button onClick = {()=>{setCommentText('');setShowCommentWindow(false)}}>Отмена</button>
                                </div>
                            </div>}
                        </div>
                        {commentsLoading?<div className='rate'></div>
                            :userRate==-1?
                                <div className='rate'>
                                    <div className='agree-rate' onClick={()=>{rate==1?setRate(-1):setRate(1);setShowComments(false);setShowCommentWindow(false)}}><CheckIcon/> Согласен</div>
                                    <div className='disagree-rate' onClick={()=>{if (rate === 0) {
                                        setRate(-1);
                                    } else {
                                        setRate(0);
                                        setShowComments(false);
                                        setShowCommentWindow(false);
                                    }
                                }}><CloseIcon/>Не согласен</div>
                            </div>:
                            userRate==0?
                                <div className='rate'>
                                    Вы не согласились с заключением ИИ:<br/> ({errors.join(', ')})
                                </div>:
                                <div className='rate'>
                                    Вы согласились с заключением ИИ
                                </div>}
                            {rate==1&&<div className='disagree-window'>
                                <div className='disagree-window-title'>Примечание (не обязательно):</div>
                                    <div className='comment-window-input'>
                                        <textarea id="auto-expanding-input" rows="1" placeholder="Введите текст примечания" value={commentText} onChange={handleComment}></textarea>
                                    </div>
                                    <div className='comments-window-button'>
                                        <button onClick = {()=>sendAgreement()}>Отправить оценку</button>
                                        <button onClick = {()=>{setCommentText('');setShowCommentWindow(false);setRate(-1);setRadioValue(-1)}}>Отмена</button>
                                    </div>
                                </div>}
                            {rate==0&&<div className='disagree-window'>
                                <div className='disagree-window-title'>Выберите вариант ошибки</div>
                                <div className='disagree-radio'>
                                    <div className='disagree-ident'>
                                        <div className='disagree-radio-ident-block local-block'>
                                            {userError['classes']!=='{}'&&<div className='disagree-radio-ident'><input id='detectradio1' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='FP' onChange={()=>setRadioSubValue(1)}></input><label htmlFor='detectradio1' id='class-block'>Ложноположительный результат</label></div>}
                                            {userError['classes']!=='{}'&&<div className='disagree-radio-ident'><input id='detectradio2' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='FN' onChange={()=>setRadioSubValue(2)}></input><label htmlFor='detectradio2' id='class-block'>Ложноотрицательный результат</label></div>}
                                            {userError['classes']!=='{}'&&<div className='disagree-radio-ident'><input id='detectradio3' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='WL' onChange={()=>setRadioSubValue(3)}></input><label htmlFor='detectradio3' id='class-block'>Неточное оконтуривание</label></div>}
                                            {userError['classes']!=='{}'&&<div className='disagree-radio-ident'><input id='detectradio4' type='checkbox' name='detectcheckbox' className='detectcheckbox' value='WC' onChange={()=>setRadioSubValue(4)}></input><label htmlFor='detectradio4' id='class-block'>Неверная классификация паталогий</label></div>}
                                            {userError['classes']=='{}'&&<div className='disagree-radio-ident'><input id='detectradio5' type='checkbox' checked={true} name='detectcheckbox' className='detectcheckbox' value='FN' disabled></input><label htmlFor='detectradio4' id='class-block'>Ложноотрицательный результат</label></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className='disagree-window-title'>Примечание (не обязательно):</div>
                                <div className='comment-window-input'>
                                    <textarea id="auto-expanding-input" rows="1" placeholder='Введите текст примечания' value={commentText} onChange={handleComment}></textarea>
                                </div>
                                <div className='comments-window-button'>
                                    <button onClick = {()=>sendRate()}>Отправить оценку</button>
                                    <button onClick = {()=>{setCommentText('');setShowCommentWindow(false);setRate(-1);setRadioValue(-1)}}>Отмена</button>
                                </div>
                            </div>}
                        <div className='admin-edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                        <div className='admin-edit-image' id='editimage'>
                            <PrismaZoom style={{display:'flex',alignItem:'center',width:'auto',height:'auto',maxHeight:'100%',maxWidth:'100%'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom>
                        </div>)}
                </div>
            )}
            <div className='stat-main-info'>
                <div className='stat-input'>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='start' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-field'><input type='date' max="2999-12-31" id='end' onChange={()=>handleSelect()}></input></div>
                    <div className='stat-input-button'>
                        <select id='select' onChange={()=>handleChange()}>
                            <option value='default'><FormattedMessage id="user_stat_select_0"/></option>
                            <option value='current_week'><FormattedMessage id="user_stat_select_1"/></option>
                            <option value='last_week'><FormattedMessage id="user_stat_select_11"/></option>
                            <option value='current_month'><FormattedMessage id="user_stat_select_2"/></option>
                            <option value='previous_month'><FormattedMessage id="user_stat_select_3"/></option>
                            <option value='current_quarter'><FormattedMessage id="user_stat_select_4"/></option>
                            <option value='previous_quarter'><FormattedMessage id="user_stat_select_5"/></option>
                            <option value='current_year'><FormattedMessage id="user_stat_select_6"/></option>
                            <option value='previous_year'><FormattedMessage id="user_stat_select_7"/></option>
                            <option value='random'><FormattedMessage id="user_stat_select_8"/></option>
                        </select>
                    </div>
                    <div className='stat-input-button'><button  onClick={()=>{setPeriod()}}><FormattedMessage id="display"/></button></div>
                </div>
                <div className='stat-input-diagram'>
                    <div className='stat-diagram'>
                    {loading?
                        (<div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>):(
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000fff',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            display: false
                                        }
                                    },
                                }
                            }}
                        />)}
                    </div>
                </div>
                {loading?(<div className='loading-div-block'>
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                </div>):
                (<div className='stat-info-text'>
                    {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_min'})+" "+Math.min(...ValArray))}
                    {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                    {str(intl.formatMessage({id:'user_bill_month_bill'})+": "+price*counter+" ₽")}
                </div>)}
                <div className='stat-info-button'>
                    {!downloadState?(<button onClick={()=>downloadFile()}><FormattedMessage id="download_stat_for_period"/></button>):(<CircularProgress sx={{color:'white'}}/>)}
                </div>
            </div>
            <div className='research-table'>
                <div className='admin-research-table-close'>
                    <div className='pagination-block'>
                        <div className='select-count'>
                            {localStorage.getItem('locale')!=='en-US'?"Отображать":"Show"}:
                            <select id="elements" onChange={handleElements}>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={400}>400</option>
                                <option value={800}>800</option>
                            </select>
                        </div>
                        <div className='serach-field'>
                            <SearchIcon/>
                            <input type='text' id = 'research2' placeholder={localStorage.getItem('locale')!=='en-US'?'Study UID/ID Пациента':'StudyUID/Patient ID'} onChange={handleResearches}/>
                        </div>
                        <Pagination count={pages} siblingCount={0} page={page} onChange={handlePage} hideNextButton={page==pages} defaultPage={1}/>
                            {page==pages&&
                                <div className='pag-div' onClick={()=>nextPage()}>
                                    {localStorage.getItem('locale')!=='en-US'?"Ещё":"More"}
                                </div>
                            }
                    </div>
                </div>
                <div className='admin-research-table-data'>
                    {tableLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(<UserTable researches={researches} sortTable={sortTable} getImage={getImage} handler={handleResearches} sort={sort} setSort={setSort}/>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Stats;