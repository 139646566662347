import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../Admin/adminstats.css'
import moment from 'moment';
import React from 'react';

function ErrorsTable(props) {
    return (
        <table className='admin-research-table-date'>
            <thead className='ad-research-thead'>
                <tr className='ad-research-tr'>
                    <th className='ad-research-th' onClick={()=>props.sortTable('organization')}>
                        <div className='ad-research-th-text' id='ad-research-organization'>
                            Организация
                            {props.sort.name==='organization'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                    <th className='ad-research-th' onClick={()=>props.sortTable('datetime')} id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                            Дата исследования
                            {props.sort.name==='datetime'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                    <th className='ad-research-th' id ='ad-research-search'>
                        <div className='search-title' id ='ad-search-name' onClick={()=>props.sortTable('studyinstanceuid')}>
                            <div className='ad-research-th-text'>
                                ID Исследования                            
                                {props.sort.name==='studyinstanceuid'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                            </div>
                        </div>
                    </th>
                    <th className='ad-research-th' onClick={()=>props.sortTable('error_message')}>
                        <div className='ad-research-th-text'>
                            Ошибка                        
                            {props.sort.name==='classes'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className='ad-research-tbody'>
                {!props.researches?
                    <tr className='ad-research-tr'>
                        <td className='ad-research-td' colSpan='6'>Нет данных</td>
                    </tr>
                :
                    props.researches.map((item)=>
                        <tr className='ad-research-tr' id={item.id} key={item.id} onClick={item.status!=='415'?()=>props.getImage(item.id):()=>{}}>
                            <td className='ad-research-td'>{item.full_name}</td>
                            <td className='ad-research-td'>{moment(item.datetime).format("DD.MM.YYYY HH:mm:ss")}</td>
                            <td className='table-sop ad-research-td' title={item.studyinstanceuid}>{item.studyinstanceuid}</td>
                            <td className='ad-research-td'>{item.error_message}</td>
                        </tr>
                )}               
            </tbody>
        </table>
    );
}

export default ErrorsTable;