import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CompareIcon from '@mui/icons-material/Compare';
import { FileUploader } from "react-drag-drop-files";
import {FormattedMessage,useIntl} from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import PrismaZoom from 'react-prismazoom'
import { useEffect, useState, useSyncExternalStore } from 'react';
import Cookies from 'js-cookie';
import './research.css'
import {useAuthUser} from 'react-auth-kit';
import { address, site_address } from '../settings';
import { upload } from '@testing-library/user-event/dist/upload';

const Research = () => {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const intl = useIntl()
    const role = useAuthUser()
    const [file,setFile]=useState("")
    const token = Cookies.get('_auth')
    const [downloading,setDownloading] = useState(false)
    const [url,setUrl]=useState('')
    const [status,setStatus] =useState(0)
    const [disabled,setDisabled] = useState(true)
    const [originalUrl,setOriginalUrl] = useState('')
    const [reslUrl,setresUrl] = useState('')
    const [loading,setLoading]=useState(false)
    const [processing,setProcessing] = useState(false)
    var invert = false;
    const [afterLoad,setAfterLoad] = useState(false)
    const [prob,setProb] = useState('0')
    const [classes,setClasses] = useState()
    const [id,setId] = useState(0)
    const [uploadURL,setUploadURL] = useState('')
    const [uploaded,setUploaded] = useState(false)

    const closeImgEditor  = () => {setIsImgEditorShown(false);}

    const openImgEditor = () => {
        setIsImgEditorShown(true);
    };
    var transliterate = function(text) {
        text = text
            .replace(/Ё/g, 'YO')
            .replace(/Й/g, 'I')
            .replace(/Ц/g, 'TS')
            .replace(/У/g, 'U')
            .replace(/К/g, 'K')
            .replace(/Е/g, 'E')
            .replace(/Н/g, 'N')
            .replace(/Г/g, 'G')
            .replace(/Ш/g, 'SH')
            .replace(/Щ/g, 'SCH')
            .replace(/З/g, 'Z')
            .replace(/Х/g, 'H')
            .replace(/Ъ/g, '')
            .replace(/ё/g, 'yo')
            .replace(/й/g, 'i')
            .replace(/ц/g, 'ts')
            .replace(/у/g, 'u')
            .replace(/к/g, 'k')
            .replace(/е/g, 'e')
            .replace(/н/g, 'n')
            .replace(/г/g, 'g')
            .replace(/ш/g, 'sh')
            .replace(/щ/g, 'sch')
            .replace(/з/g, 'z')
            .replace(/х/g, 'h')
            .replace(/ъ/g, "'")
            .replace(/Ф/g, 'F')
            .replace(/Ы/g, 'I')
            .replace(/В/g, 'V')
            .replace(/А/g, 'a')
            .replace(/П/g, 'P')
            .replace(/Р/g, 'R')
            .replace(/О/g, 'O')
            .replace(/Л/g, 'L')
            .replace(/Д/g, 'D')
            .replace(/Ж/g, 'ZH')
            .replace(/Э/g, 'E')
            .replace(/ф/g, 'f')
            .replace(/ы/g, 'i')
            .replace(/в/g, 'v')
            .replace(/а/g, 'a')
            .replace(/п/g, 'p')
            .replace(/р/g, 'r')
            .replace(/о/g, 'o')
            .replace(/л/g, 'l')
            .replace(/д/g, 'd')
            .replace(/ж/g, 'zh')
            .replace(/э/g, 'e')
            .replace(/Я/g, 'Ya')
            .replace(/Ч/g, 'CH')
            .replace(/С/g, 'S')
            .replace(/М/g, 'M')
            .replace(/И/g, 'I')
            .replace(/Т/g, 'T')
            .replace(/Ь/g, "'")
            .replace(/Б/g, 'B')
            .replace(/Ю/g, 'YU')
            .replace(/я/g, 'ya')
            .replace(/ч/g, 'ch')
            .replace(/с/g, 's')
            .replace(/м/g, 'm')
            .replace(/и/g, 'i')
            .replace(/т/g, 't')
            .replace(/ь/g, "'")
            .replace(/б/g, 'b')
            .replace(/ю/g, 'yu');
        return text;
    };
    useEffect(()=>{
        if(document.getElementById('file'))document.getElementById('file').addEventListener('change', function(){
            if( this.value ){
              setDisabled(false)
            } else {
              setDisabled(true)
            }
          });
    })
    const resetparam = () => {
        document.getElementById('con').value=100;
        document.getElementById('br').value=100;
        invert=false
        document.getElementById('image').style.filter = "brightness(100%) contrast(100%)";
    }
    const change = () => {
        var b = document.getElementById('br').value;
        var c = document.getElementById('con').value;
        document.getElementById('image').style.filter="brightness("+b+"%) contrast("+c+"%) invert(0%)"
        if(invert)document.getElementById('image').style.filter+=" invert(100%)"
    }
    const showImage = () => {
        setUploaded(false)
        setUploadURL(window.URL.createObjectURL(document.getElementById('file').files[0]))  
    }
    const getConlusion = () =>{
        let body = {
            item_id:id
        }
        setDownloading(true)
        fetch(address+'/individual/data/conclusion', {
        method: 'POST',
        headers: {
            'Content-type':'application/json',
            'Authorization': 'Bearer '+token,
        },
        body:JSON.stringify(body)
        }).then((response) => {if (response.ok) return response.blob()
            else {
                if(response.status==403){alert('Доступ запрещён'); return false}
                if(response.status==404){alert("Исследование недоступно"); return false}
                if(response.status==502){alert("Сервер не отвечает"); return false}
                if(response.status==500){alert("Исследование не найдено"); return false}
                setLoading(false);
                setDownloading(false);return false}})
            .then((blob) => {
                    if (blob!==false) {
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Заключение.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                        setDownloading(false)
                    }
                    else {
                        setLoading(false); 
                        return 0
                    }
        }).catch(error=>setDownloading(false))
    }
    const sendImage = () =>{
        if(!document.getElementById('file').value){alert(intl.formatMessage({id:'click_browse'}));return 0}
        let url = URL.createObjectURL(document.getElementById('file').files[0])
        setOriginalUrl(url)
        let language = window.localStorage.getItem('locale')
        setLoading(true)
        setProcessing(true)
        const fd = new FormData()
        fd.append('file',document.getElementById('file').files[0])
        fetch(address+'/predict/V4', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+token,
            'SOP':'file: '+transliterate(document.getElementById('file').files[0].name.toString()).toString()+' (On-line proccessing)',
            'Client-language':language
        },
        body:fd
        }).then(resp=>{if (resp.ok) {
            for(let header of resp.headers){
                if(header[0]=='prob')setProb(header[1]);
                if(header[0]=='classes')setClasses(JSON.parse(header[1]));
                if(header[0]=='item-id')setId(header[1])
            }
            return resp
        }else {if(resp.status==401)alert("Вы не авторизовались либо срок действия авторизации истёк, пожалуйста перезайдите");if(resp.status==400){alert("Лимит исследований исчерпан");setAfterLoad(false); setStatus(400); return 0;}if(resp.status==405)alert(intl.formatMessage({id:'limit'}));if(resp.status==422)alert(intl.formatMessage({id:'no_legkie'}));if(resp.status==415)alert(intl.formatMessage({id:'media_type'}));setLoading(false);return 0}}).then((response) => {
            if(response!==0) {const reader = response.body.getReader();
            return new ReadableStream({
              start(controller) {
                return pump();
                function pump() {
                  return reader.read().then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }
                    controller.enqueue(value);
                    return pump();
                  });
                }
              }
            })}
            else {setLoading(false); return 0}
          }).then((stream) => {if(stream!==0)return new Response(stream);else return 0}).then((response) => {if(response!==0)return response.blob();else return 0;}).then((blob) => {if(blob!==0)return URL.createObjectURL(blob);else return 0;})
          .then((url) => {
            if(url!==0){setUrl(url)
            setresUrl(url)
            setLoading(false)
            if(status==400)setAfterLoad(false)
            else setAfterLoad(true)
            setStatus(0)
            setTimeout(()=>document.getElementById('img').src = url,200)}
            else setAfterLoad(false)
        }).catch((err) => console.log(err));
    }
    const clearImage = () =>{
        setProcessing(false)
        if(document.getElementById('file'))document.getElementById('file').value=''
        setDisabled(true)
        setAfterLoad(false)
    }
    const clearField = () =>{
        setUploaded(false)
        setDisabled(true)
        if(document.getElementById('file'))document.getElementById('file').value=''
    }
    const inputHandler =(e)=>{
        setFile(e.target.files[0].name);
    }
    return (
        <div className='user-research'>
            {isImgEditorShown && (
                <div className='edit-div'>
                    <div className='edit-header'>
                        <div className='edit-header-btn' onClick={()=>closeImgEditor()}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                    </div>
                        <div className='edit-image'>
                            <PrismaZoom style={{display:'flex',alignItem:'center',width:'auto',height:'88%',maxHeight:'88%',maxWidth:'95%'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom>
                        </div>
                    <div className='edit-param'>
                        <div className='next-prev'>
                            <a className='reset-btn' title={intl.formatMessage({id:'original_image'})} onClick={()=>{setUrl(originalUrl)}}><ArrowBackIcon sx={{fontSize:40,color:"white"}}/></a>
                            <a className='reset-btn' title={intl.formatMessage({id:'result'})} onClick={()=>{setUrl(reslUrl)}}><ArrowForwardIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='range'>
                            <input id="br" type="range" min="0" defaultValue="100" max="500" onChange={()=>change()}/>
                            <label><FormattedMessage id="brightness"/></label>
                        </div>
                        <div className='range'>
                            <input id='con' type='range' min='0' max='500' defaultValue="100" onChange={()=>change()}/>
                            <label><FormattedMessage id="contrast"/></label>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn' title={intl.formatMessage({id:'invert_image'})} onClick={()=>{invert=!invert;change()}}><CompareIcon  sx={{fontSize:40,color:"white"}}/></a>
                        </div>
                        <div className='reset-btn-div'>
                            <a className='reset-btn'title={intl.formatMessage({id:'reset_settings'})} onClick={()=>resetparam()}><RestartAltIcon sx={{fontSize:40,color:"white"}}/></a>
                        </div>  
                    </div>
                </div>
            )}
            <div className='output-file'>
                <div className='output-file-info'>
                    <div className='output-info-field'>
                        <div className='output-file-description'>
                            <FormattedMessage id="user_online_proccessing_select"/>
                        </div>
                        {/* <div className='software-version'>
                            <div className='software-version-title'>
                                Версия ПО для обработки:
                            </div>
                            <div className='software-version-radio'>
                                <input type='radio' name='software-radio' id='rad1' onChange={()=>setRadioValue(0)} checked={radioValue==0}></input><label htmlFor='rad1'>3.3</label>
                                <input type='radio' name='software-radio' id='rad2' onChange={()=>setRadioValue(1)} checked={radioValue==1}></input><label htmlFor='rad2'>4.0</label>
                            </div>
                        </div> */}
                        <div className='output-file-button'>
                            {loading?
                            <div className='div-loading'>
                                <CircularProgress sx={{color:'white'}}/>
                            </div>:
                            !processing?
                                <button disabled={disabled} onClick={()=>{sendImage(true)}}>{localStorage.getItem('locale')!=='en-US'?"Анализировать":"Analyse"}</button>
                            :
                                <button disabled={disabled} onClick={()=>{clearImage()}}>{localStorage.getItem('locale')!=='en-US'?"Новый файл":"New file"}</button>}
                        </div>
                        {(afterLoad&&prob==='0')&&
                                <div className='file-description'>
                                    {role().role=='individual'?
                                        <div className='output-conclusion-prob'>
                                            <p>{localStorage.getItem('locale')!=='en-US'?"Патологических изменений не выявлено":"No pathological changes were detected"}</p>                                     
                                            {!downloading?
                                            <button disabled={disabled} onClick={()=>{getConlusion()}}>Скачать протокол</button>:
                                            <div className='div-loading'>
                                                <CircularProgress sx={{color:'white'}}/>
                                            </div>}
                                        </div>
                                        :
                                        afterLoad?
                                        <div className='output-conclusion-prob'>
                                            <p>{localStorage.getItem('locale')!=='en-US'?"Патологических изменений не выявлено":"No pathological changes were detected"}</p>
                                        </div>:<></>
                                    }
                                    
                                </div>
                        }
                        {(afterLoad&&prob!=='0')&&
                                <div className='file-description'>
                                    {/* {role().role=='individual'? */}
                                    <div className='output-conclusion-prob'>
                                        <p>{localStorage.getItem('locale')!=='en-US'?"Выявлены патологические изменения. Вероятность:":"Pathological changes have been revealed. Probability"} {prob}.</p> 
                                        {role().role=='individual'? !downloading?
                                            <button disabled={disabled} onClick={()=>{getConlusion()}}>Скачать протокол</button>:
                                            <div className='div-loading'>
                                                <CircularProgress sx={{color:'white'}}/>
                                            </div>:
                                            <></>}
                                        </div>
                                        <div className='output-conclusion'>
                                            {localStorage.getItem('locale')!=='en-US'?"Перечень найденных патологий:":"The list of pathologies found:"}
                                        </div>
                                        {classes&&Object.values(classes).map((item,index)=>
                                            <div className='output-conclusion' key={item}>
                                                {(index+1)+'. '+item}
                                            </div>
                                        )}
                                </div>
                        }
                    </div>
                </div>
                <div className='output-file-field'>
                    <div className='output-file-title'>{localStorage.getItem('locale')!=='en-US'?'Окно с результатом обработки вашего исследования. Нажмите для взаимодействия':'A window with the result of processing your research. Click to interact'}</div>
                    {!processing?
                        <div className='filing' id='filing' onClick={()=>showImage()}>
                            <input type="file" name="file" id="file" className="inputfile" onChange={inputHandler}></input>
                            <label htmlFor="file" id='label'>{disabled?localStorage.getItem('locale')!=='en-US'?"Нажмите, чтобы выбрать файл":"Click to select a file":
                                document.getElementById('file')&&document.getElementById('file').files[0].name.toString()!==''&&
                                    <div className='uploaded-image'>
                                        {uploaded&&
                                            <img id='imagination' src={uploadURL} alt=''/>
                                        }
                                        <div className='uploaded-image-button'>
                                            {localStorage.getItem('locale')!=='en-US'?"Текущий файл для отправки (нажмите для изменения):":"The current file to send, click to change:"}
                                            <br/>{file!==''?<>{file}</>:<></>}
                                        </div>
                                    </div>
                                }
                            </label>
                            {/* <div className='onDragDiv' onDrop={e=>onDropHandler(e)}>
                                Ghbasddtn
                            </div> */}
                        </div>
                        :
                        loading?(
                            <div className='div-loading'>
                                <CircularProgress sx={{color:'white'}}/>
                            </div>
                        ):(
                            <img id='img' src='' alt='' onClick={()=>openImgEditor()}/>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Research;