import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import {PhotoSlider} from 'react-photo-view';
import photo1 from '../../images/news/news-34.png';
import photo2 from '../../images/news/news-34-1.png';
import photo4 from '../../images/news/news-34-2.png';
import photo5 from '../../images/news/news-34-3.png';
import photo3 from '../../images/news/news-34-4.png';
import photo6 from '../../images/news/news-34-5.png';
import photo7 from '../../images/news/news-34-6.png';
import photo8 from '../../images/news/news-34-7.png';


import "../../styles/news.css"

const News = () => {
    const intl = useIntl()
    const images = [
        photo1,photo2,photo3,photo4,photo5,photo6,photo7,photo8
    ];
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const ChosenImage = (number) =>{
        setIndex(number)
        setVisible(true)
    }
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    const stroka=(text)=>{
        return(
            <div className='new-cont-text'>
                {text}
            </div>
        )
    }
    return (
        <div className='new-content'>
            <div className='news-page-header'>
                <div className='main-header-head'>
                    {localStorage.getItem('locale')=='ru-RU'?
                        (
                        <div className='main-header-language' onClick={()=>transreload('en-US')}>
                            <img className='lang-img' src={require('../../images/lang-en.png')} alt=""/>
                        </div>
                        ):(
                        <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                            <img className='lang-img' src={require('../../images/lang-ru.png')} alt=""/>
                        </div>)}
                    <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/news"><FormattedMessage id="main_link_news"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        {/* <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk> */}
                        <Lnk className='link' to="/main/#contacts"><FormattedMessage id="main_link_contacts"/></Lnk>
                    </div>
                </div>
            </div>
            <div className='new-cont'>
                <div className='new-cont-content'>
                    <div className='new-cont-title'><FormattedMessage id="news_34_title"/></div>
                    <PhotoSlider
                        images={images.map((item) => ({ src: item, key: item }))}
                        visible={visible}
                        onClose={() => setVisible(false)}
                        index={index}
                        onIndexChange={setIndex}
                    />
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo1} onClick={()=>ChosenImage(0)}></img></div>
                    {stroka(intl.formatMessage({id:'news_34_text1'}))}
                    {stroka(intl.formatMessage({id:'news_34_text2'}))}
                    {stroka(intl.formatMessage({id:'news_34_text3'}))}
                    {stroka(intl.formatMessage({id:'news_34_text4'}))}
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo2} onClick={()=>ChosenImage(1)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo3} onClick={()=>ChosenImage(2)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo4} onClick={()=>ChosenImage(3)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo5} onClick={()=>ChosenImage(4)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo6} onClick={()=>ChosenImage(5)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo7} onClick={()=>ChosenImage(6)}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo8} onClick={()=>ChosenImage(7)}></img></div>
                </div>
                {(document.documentElement.scrollWidth>500)?(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default News;