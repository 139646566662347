import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import './adminnewspage.css'

const Adminnewspage = () => {
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'},{'indent': '+1'},{ 'align': ['center','','right','justify'] }],
            ['link', 'image'],
            ['clean'],
            
        ],
    }
    const [value, setValue] = useState('');
    return (
        <div className='admin-news-edit'>
            <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} />
        </div>
    );
};

export default Adminnewspage;