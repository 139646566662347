import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import {PhotoSlider} from 'react-photo-view';
import photo1 from '../../images/news/news-39.jpg';
import photo2 from '../../images/news/news-39-1.jpg';
import photo3 from '../../images/news/news-39-2.jpg';
import photo4 from '../../images/news/news-39-3.jpg';
import "../../styles/news.css"

const News = () => {
    const intl = useIntl()
    const images = [
        photo1
    ];
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const ChosenImage = (number) =>{
        setIndex(number)
        setVisible(true)
    }
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    const stroka=(text)=>{
        return(
            <div className='new-cont-text'>
                {text}
            </div>
        )
    }
    return (
        <div className='new-content'>
            <div className='news-page-header'>
                <div className='main-header-head'>
                    {
                        localStorage.getItem('locale')=='ru-RU'?
                            (
                            <div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={require('../../images/lang-en.png')} alt=""/>
                            </div>
                            ):(
                            <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={require('../../images/lang-ru.png')} alt=""/>
                            </div>
                            )
                    }
                    <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/news"><FormattedMessage id="main_link_news"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        {/* <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk> */}
                        <Lnk className='link' to="/main/#contacts"><FormattedMessage id="main_link_contacts"/></Lnk>
                    </div>
                </div>
            </div>
            <div className='new-cont'>
                <div className='new-cont-content' style={{marginBottom:30}}>
                    <div className='new-cont-title'><FormattedMessage id="news_39_title"/></div>
                    {stroka(intl.formatMessage({id:'news_39_text1'}))}
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo1}></img></div>
                    {stroka(intl.formatMessage({id:'news_39_text2'}))}
                    {stroka(intl.formatMessage({id:'news_39_text3'}))}
                    {stroka(intl.formatMessage({id:'news_39_text4'}))}
                    {stroka(intl.formatMessage({id:'news_39_text5'}))}
                    {stroka(intl.formatMessage({id:'news_39_text6'}))}
                    {stroka(intl.formatMessage({id:'news_39_text7'}))}
                    {stroka(intl.formatMessage({id:'news_39_text8'}))}
                    {stroka(intl.formatMessage({id:'news_39_text9'}))}
                    {stroka(intl.formatMessage({id:'news_39_text10'}))}
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo2}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo3}></img></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={photo4}></img></div>
                </div>
                {(document.documentElement.scrollWidth>500)?(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default News;