import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../Admin/adminstats.css'
import moment from 'moment';
import React from 'react';

function UserErrorsTable(props) {
    return (
        <table className='admin-research-table-date'>
            <thead className='ad-research-thead'>
                <tr className='ad-research-tr'>
                    <th className='ad-research-th' onClick={()=>props.sortTable('datetime')} id='ad-research-datetime'>
                        <div className='ad-research-th-text' >
                        {localStorage.getItem('locale')!=='en-US'?"Дата исследования":"Study date"}
                            {props.sort.name==='datetime'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                    <th className='ad-research-th' id ='ad-research-search'>
                        <div className='search-title' id ='ad-search-name' onClick={()=>props.sortTable('sopinstanceuid')}>
                            <div className='ad-research-th-text'>
                                {localStorage.getItem('locale')!=='en-US'?"ID Исследования (StudyUID)":"Study ID (StudyUID)"}                              
                                {props.sort.name==='sopinstanceuid'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                            </div>
                        </div>
                    </th>
                    <th className='ad-research-th' onClick={()=>props.sortTable('error_message')}>
                        <div className='ad-research-th-text'>
                            {localStorage.getItem('locale')!=='en-US'?"Ошибка":"Error"}                       
                            {props.sort.name==='error_message'?(props.sort.direction===false?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>):<div className='empt'><ArrowDownwardIcon/></div>}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className='ad-research-tbody'>
                {!props.researches?
                    <tr className='ad-research-tr'>
                        <td className='ad-research-td' colSpan='6'>Нет данных</td>
                    </tr>
                :
                    props.researches.map((item)=>
                        <tr className='ad-research-tr' id={item.id} key={item.id} onClick={item.status!=='415'?()=>props.getImage(item.id):()=>{}}>
                            <td className='ad-research-td'>{moment(item.datetime).format("DD.MM.YYYY HH:mm:ss")}</td>
                            <td className='table-sop ad-research-td' title={item.studyinstanceuid}>{item.studyinstanceuid}</td>
                            <td className='ad-research-td'>{item.error_message==="Необрабатываемые данные"?(localStorage.getItem('locale')!=='en-US'?"Необрабатываемые данные":"Unprocessable entity"):localStorage.getItem('locale')!=='en-US'?"Неподдерживаемый формат файла":"Unsupported file format"}</td>
                        </tr>
                )}               
            </tbody>
        </table>
    );
}

export default UserErrorsTable;