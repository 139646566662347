import React from 'react';
import './newsfield.css'

const Newsfield = (props) => {
    return (
        <div className='news-field'>
            <div className='news-field-img'>
                <img src={require(`../images/news/${props.img}`)}/>
            </div>
            <div className='news-field-text-btn'>
                <div className='news-field-text'>
                    {props.text}
                </div>
                <div className='news-field-btn'>    
                    <button onClick={()=>{window.location.assign("http://"+window.location.host+"/admin/adminnews/"+props.id)}}>Редактировать</button>
                    <button id='btn-delete'>Удалить</button>
                </div>
            </div>
        </div>
    );
};

export default Newsfield;