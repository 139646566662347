import {FormattedMessage} from 'react-intl';
import React from 'react';
import './news.css'
import { site_address } from '../../settings';

const Newscard = (props) => {
    const path = props.id
    return (
        <div className='news-card' id='newsid' onClick={()=>window.location.assign(`${site_address}/news/${props.id}`)}>
            <div className='news-card-img' >
                <img src={require(`./img/${props.img}`)} alt=""/>
            </div>
            <div className='news-card-text'>
                {props.id==9?(<div><FormattedMessage id="news_9_title_11"/><br/><FormattedMessage id="news_9_title_12"/></div>):(props.text)}
            </div>
            <div className='news-card-btn'>
                <div className='news-card-btn-block'>
                    <div className="news-card-btn-date">
                        {props.date}
                    </div>
                    <div className='news-card-btn-type'>
                        {props.type}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newscard;