import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import { useState,useEffect } from 'react';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import '../../User/usermain.css'
import { address, address_with_api } from '../../settings';

Chart.register(CategoryScale);

const IndividualFinance = () => {
    const intl = useIntl()
    const [price,setPrice] = useState(36)
    const [count,setCount] = useState(0)
    const [payVisible,setPayVisible] = useState(false)
    const token = Cookies.get('_auth')
    const [packPrice,setPackPrice] = useState(36)
    const [loading,setLoading] = useState(true)
    const [userData,setUserData] = useState([])
    const [tarif,setTarif] = useState('piece')
    const [user,setUser] = useState()
    const [disabled,setDisabled] = useState(true)
    const [payLoading,setPayLoading] = useState(false)
    const [selector,setSelector] = useState('piece')
    const [changeVisible,setChangeVisible] = useState(false)
    useEffect(()=>{
        fetch(address_with_api+'/user/info', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            setUser(result);
            setLoading(false)
            if(result.tarif_type=='piece')setTarif('piece')
            if(result.tarif_type=='pack')setTarif('pack')
            setLoading(false)
          }
          else alert("Неверно введённые данные")
        })
      },[])
    const str = (txt) => {
        return (<div className='info-txt'>
            {txt}
        </div>)
    }
    const handle = () =>{
        if(document.getElementById('count')&&document.getElementById('count').value!=0)setDisabled(false)
        if(document.getElementById('count'))setCount(document.getElementById('count').value)
        if(document.getElementById('count')&&document.getElementById('count').value>20&&document.getElementById('count').value<=60)setPrice(28)
        if(document.getElementById('count')&&document.getElementById('count').value>=60)setPrice(24)
        if(document.getElementById('count')&&document.getElementById('count').value<=20)setPrice(36)
    }
    const radioHandler = (e) =>{
        if(e.target.value==20){setPackPrice(35);setCount(20)}
        if(e.target.value==60){setPackPrice(28);setCount(60)}
        if(e.target.value==100){setPackPrice(22);setCount(100)}
        setDisabled(false)
    }
    const resetCount = () =>{
        setPayVisible(false)
        setPackPrice(0)
        setCount(0)
        setPrice(36)
        setDisabled(true)
        setChangeVisible(false)
    }
    const selectHandler = (e) => {
        setSelector(e.target.value)
        setTarif(e.target.value)
    }
    const addResearches = (number) =>{
        setPayLoading(true)
        let body={
            research_size:number
        }
        fetch(address+'/individual/research/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(response=>response.json()).then(result=>{
            setPayLoading(false)
            setUser({...user,['research_size']:result})
            setPayVisible(false)
            setCount(0)
        })
    }
    const changeTarif = () =>{
        let body={
            tarif_type:selector
        }
        fetch(address+'/individual/tarif/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(response=>response.json()).then(result=>{
            setChangeVisible(false)
            setTarif(result)
            setUser({...user,['tarif_type']:result})
        })
    }
    return (
        <div className='user-main'>
            {payVisible&&<div className='finance-form-field'>
                {tarif==='piece'?
                <div className='finance-pay-form'>
                    <div className='finance-pay-form-title'>Окно оплаты</div>
                    <div className='finance-pay-form-data'>
                        <div className='finance-pay-form-field'>
                            Ваш текущий тариф: Поштучный 
                        </div>
                        <div className='finance-pay-form-field'>
                            Введите количество исследований
                            <input type="number" placeholder='0' id='count' value={count} onChange={handle}></input>
                        </div>
                        <div className='finance-pay-form-field'>
                            Стоимость одного исследования, руб.: {price}
                        </div>
                        <div className='finance-pay-form-field'>
                            Итоговая сумма, руб. : {count*price}
                        </div>
                        <div className='finance-pay-form-button'>
                            {payLoading?'Обработка':<button className='finance-pay-button' disabled={disabled} onClick={()=>addResearches(count)}>Оплатить</button>}
                            <button className='finance-pay-cancel-button' onClick={()=>resetCount()}>Отмена</button>
                        </div>
                    </div>
                    </div>
                    :
                    <div className='finance-pay-form'>
                        <div className='finance-pay-form-title'>Окно оплаты</div>
                        <div className='finance-pay-form-data'>
                            <div className='finance-pay-form-field'>
                                Ваш текущий тариф: Пакетный 
                            </div>
                            <div className='finance-pay-form-field'>
                                Выберите желаемое количество исследований:
                            </div>
                            <div className='finance-pay-form-field'>
                                <input type="radio" name="size" id="size_1" value="20" onChange={radioHandler}/>
                                <label htmlFor="size_1">20</label>
                                
                                <input type="radio" name="size" id="size_2" value="60" onChange={radioHandler}/>
                                <label htmlFor="size_2">60</label>
                                
                                <input type="radio" name="size" id="size_3" value="100" onChange={radioHandler}/>
                                <label htmlFor="size_3">100</label>
                            </div>
                            <div className='finance-pay-form-field'>
                                Стоимость одного исследования: {(packPrice)} рублей
                            </div>
                            <div className='finance-pay-form-field'>
                                Итоговая сумма: {Math.ceil(packPrice*count)} рублей
                            </div>
                            <div className='finance-pay-form-button'>
                                {payLoading?'Обработка...':<button className='finance-pay-button' disabled={disabled} onClick={()=>addResearches(count)}>Оплатить</button>}
                                <button className='finance-pay-cancel-button' onClick={()=>resetCount()}>Отмена</button>
                            </div>
                        </div>
                    </div>
                }
            </div>}
            {changeVisible&&<div className='finance-form-field'>
                <div className='finance-pay-form'>
                    <div className='finance-pay-form-title'>Смена Тарифа</div>
                    <div className='finance-pay-form-data'>
                        <div className='finance-pay-form-field'>
                            Выберите подходящий тариф 
                            <select onChange={selectHandler} value={tarif}>
                                <option value='piece'>Поштучный</option>
                                <option value='pack'>Пакетный</option>
                            </select>
                        </div>
                        <div className='finance-pay-form-button'>
                            <button className='finance-pay-button' onClick={()=>changeTarif()}>Сохранить</button>
                            <button className='finance-pay-cancel-button' onClick={()=>resetCount()}>Отмена</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='personal-info'>
                <div className='block-name'>
                    <div className='block-name-text'>Общие сведения</div>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>)
                :
                    (<div className='block-content'>
                    {str("Текущий тариф: "+ (user.tarif_type&&user.tarif_type==='pack'?'Пакетный':'Поштучный'))}
                    {str("Доступно исследований: "+user.research_size)}
                    <div className='info-txt'>
                        {"Статус аккаунта: "}{user.research_size==0 ?"Нет доступных исследований":"Можно обрабатывать снимки"}
                    </div>
                    <div className='individ-finance-buttons'>
                        <button onClick={()=>setPayVisible(true)}>
                            Добавить исследования
                        </button>
                        <button onClick={()=>setChangeVisible(true)}>
                            Сменить тариф
                        </button>
                    </div>
                </div>
                )}
            </div>
            <div className='tarif-info'>
                <div className='tarif-block-name'>
                    <div className='tarif-name-text'>Сведения о тарифах</div>
                    <div className='tarif-table'>
                        <table className='tarif-table-data'>
                            <thead>
                                <tr className='tarif-table-tr'>
                                    <th className='tarif-table-th'colSpan={2}>Поштучный</th>
                                    <th className='tarif-table-th' colSpan={2}>Пакетный</th>
                                </tr>
                                <tr>
                                    <th className='tarif-table-th'>Количество</th>
                                    <th className='tarif-table-th'>Цена</th>
                                    <th className='tarif-table-th'>Количество</th>
                                    <th className='tarif-table-th'>Цена</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='tarif-table-td'>до 20 шт.</td>
                                    <td className='tarif-table-td'>36 руб. /шт.</td>
                                    <td className='tarif-table-td'>20 шт.</td>
                                    <td className='tarif-table-td'>700 руб.</td>
                                </tr>
                                <tr>
                                    <td className='tarif-table-td'>от 20 до 60 шт.</td>
                                    <td className='tarif-table-td'>30 руб. /шт.</td>
                                    <td className='tarif-table-td'>60 шт.</td>
                                    <td className='tarif-table-td'>1680 руб.</td>
                                </tr>
                                <tr>
                                    <td className='tarif-table-td'>60 шт. и выше</td>
                                    <td className='tarif-table-td'>24 руб. /шт.</td>
                                    <td className='tarif-table-td'>100 шт.</td>
                                    <td className='tarif-table-td'>2200 руб.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualFinance;