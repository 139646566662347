import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import '../styles/registration.css'
import React from 'react';


const Fail = () => {
    let history = useNavigate()

    return (
        <div className='fail-page'>
            <CancelIcon sx={{color:'#eb3f3f',fontSize:350}}/>
            <p className='fail-title'>Платёж не прошёл</p><br/><p className='fail-text'>Возможно, это был временный сбой - попробуйте позднее</p>
            <div className='fail-button'><button onClick={()=>history(-1)}>Вернуться на главную</button></div>
        </div>
    );
}

export default Fail;