import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


export const SidebarData = [
  {
    title: "Главная",
    path: "usermain",
    icon:<HomeOutlinedIcon fontSize="large" />
  },
  {
    title: "Статистика",
    path: "stat",
    icon:<QueryStatsIcon fontSize="large" />
  },
  {
    title: "Ошибочные исследования",
    path: "errors",
    icon:<ReportGmailerrorredIcon fontSize="large" />
  },
  {
    title: "Онлайн обработка",
    path: "research",
    icon:<ImageSearchIcon fontSize="large" />
  }
];
export const enSidebarData = [
  {
    title: "Main",
    path: "usermain",
    icon:<HomeOutlinedIcon fontSize="large" />
  },
  {
    title: "Statistic",
    path: "stat",
    icon:<QueryStatsIcon fontSize="large" />
  },
  {
    title: "Incorrect researches",
    path: "errors",
    icon:<ReportGmailerrorredIcon fontSize="large" />
  },
  {
    title: "On-line proccessing",
    path: "research",
    icon:<ImageSearchIcon fontSize="large" />
  }
];
