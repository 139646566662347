import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import {PhotoSlider} from 'react-photo-view';
import { Link } from 'react-router-dom';
import React, { useEffect,useState } from 'react';
import img1 from '../images/res-1.jpg'
import img2 from '../images/res-2.jpg'
import img3 from '../images/res-3.png'
import img4 from '../images/res-4.png'
import img5 from '../images/res-5.png'
import img6 from '../images/A7.png'
import img7 from '../images/res-7.png'
import '../styles/allnews.css'
import '../styles/documents.css'
const Documents = (props) => {
    const [visible, setVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const images = [
        img1,img2,img3,img4,img5,img6,img7
    ];
    const ChosenImage = (number) =>{
        setIndex(number)
        setVisible(true)
    }
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    return (
        <div className='documents'>
            <div className='all-news-page'>
                <div className='news-page-header'>
                    <div className='main-header-head'>
                        {localStorage.getItem('locale')=='ru-RU'?
                            (<div className='main-header-language' onClick={()=>transreload('en-US')}>
                                <img className='lang-img' src={require('../images/lang-en.png')} alt=""/>
                            </div>
                            ):(<div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                                <img className='lang-img' src={require('../images/lang-ru.png')} alt=""/>
                            </div>)}
                            <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                    </div>
                    <div className='main-header-nav'>
                        <div className='main-header-link'>
                            <Lnk className='link' to="/main"><FormattedMessage id="allnews_link_main"/></Lnk>
                            <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                            <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                            <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk>
                            <Lnk className='link' to="/main/#contact"><FormattedMessage id="main_link_contacts"/></Lnk>  
                            <Lnk className='link' to="/news"><FormattedMessage id="main_link_news"/></Lnk>                     
                        </div>
                    </div>
                    <div className='news-page-title'><FormattedMessage id="main_documents_title"/></div>
                </div>
                {(document.documentElement.scrollWidth>1024)?
                <div className='documents-page-content'>
                    <PhotoSlider
                        images={images.map((item) => ({ src: item, key: item }))}
                        visible={visible}
                        onClose={() => setVisible(false)}
                        index={index}
                        onIndexChange={setIndex}
                    />
                    <div className='first-content-block'>
                        <img src={img1} onClick={()=>ChosenImage(0)}/>
                        <img src={img2} onClick={()=>ChosenImage(1)}/>
                        <img src={img3} onClick={()=>ChosenImage(2)}/>
                        <img src={img4} onClick={()=>ChosenImage(3)}/>
                    </div>
                    <div className='first-content-block'>
                        <img src={img5} onClick={()=>ChosenImage(4)}/>
                        <img src={img6} onClick={()=>ChosenImage(5)}/>
                        <img src={img7} onClick={()=>ChosenImage(6)}/>
                    </div>
                </div>
                :(document.documentElement.scrollWidth>780&&document.documentElement.scrollWidth<1024)?
                <div className='documents-page-content'>
                    <PhotoSlider
                        images={images.map((item) => ({ src: item, key: item }))}
                        visible={visible}
                        onClose={() => setVisible(false)}
                        index={index}
                        onIndexChange={setIndex}
                    />
                    <div className='first-content-block'>
                        <img src={img1} onClick={()=>ChosenImage(0)}/>
                        <img src={img2} onClick={()=>ChosenImage(1)}/>
                    </div>
                    <div className='first-content-block'>
                        <img src={img3} onClick={()=>ChosenImage(2)}/>
                        <img src={img4} onClick={()=>ChosenImage(3)}/>
                    </div>
                    <div className='second-content-block'>
                        <img src={img5} onClick={()=>ChosenImage(4)}/>
                        <img src={img6} onClick={()=>ChosenImage(5)}/>
                        <img src={img7} onClick={()=>ChosenImage(6)}/>
                    </div>
                </div>
                :(document.documentElement.scrollWidth>500&&document.documentElement.scrollWidth<780)?
                <div className='documents-page-content'>
                    <PhotoSlider
                        images={images.map((item) => ({ src: item, key: item }))}
                        visible={visible}
                        onClose={() => setVisible(false)}
                        index={index}
                        onIndexChange={setIndex}
                    />
                    <div className='first-content-block'>
                        <img src={img1} onClick={()=>ChosenImage(0)}/>
                        <img src={img2} onClick={()=>ChosenImage(1)}/>
                        <img src={img3} onClick={()=>ChosenImage(2)}/>
                        <img src={img4} onClick={()=>ChosenImage(3)}/>
                    </div>
                    <div className='second-content-block'>
                        <img src={img5} onClick={()=>ChosenImage(4)}/>
                        <img src={img6} onClick={()=>ChosenImage(5)}/>
                        <img src={img7} onClick={()=>ChosenImage(6)}/>
                    </div>
                </div>:document.documentElement.scrollWidth<500?
                <div className='documents-page-content'>
                    <PhotoSlider
                        images={images.map((item) => ({ src: item, key: item }))}
                        visible={visible}
                        onClose={() => setVisible(false)}
                        index={index}
                        onIndexChange={setIndex}
                    />
                    <div className='first-content-block'>
                        <img src={img1} onClick={()=>ChosenImage(0)}/>
                    </div>
                    <div className='first-content-block'>
                        <img src={img2} onClick={()=>ChosenImage(1)}/>
                    </div>
                    <div className='first-content-block'>
                        <img src={img3} onClick={()=>ChosenImage(2)}/>
                    </div>
                    <div className='first-content-block'>
                        <img src={img4} onClick={()=>ChosenImage(3)}/>
                    </div>
                    <div className='second-content-block'>
                        <img src={img5} onClick={()=>ChosenImage(4)}/>
                    </div>
                    <div className='second-content-block'>
                        <img src={img6} onClick={()=>ChosenImage(5)}/>
                    </div>
                    <div className='second-content-block'>
                        <img src={img7} onClick={()=>ChosenImage(6)}/>
                    </div>
                </div>:<div></div>
                }
                {(document.documentElement.scrollWidth>500)?(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='about-footer'>
                        <div className='about-footer-content'>
                            <div className='about-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('./subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='about-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='about-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Documents;