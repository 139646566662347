import React from 'react';
import './partner.css'
import nii from './img/nii.svg'
import mgu from './img/mgu.svg'
const Partner = (props) => {
    return (
        <div className='partner-form' id={props.id}>
            <a href={props.link} target="_blank" rel="noreferrer">
                <div className='partner-img'>
                    {props.id!=="partner-3"&&props.id!=="partner-7"?
                        (<img src={require(`./img/${props.img}`)} alt={props.text} title={props.text}/>)
                        :
                        props.id==="partner-3"?
                            (<img src={nii} alt={props.text} title={props.text}/>)
                            :
                            (<img src={mgu} alt={props.text} title={props.text}/>)
                    }
                </div>
            </a>
        </div>
    );
};

export default Partner;