import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import { useState,useEffect } from 'react';
import { CategoryScale } from 'chart.js';
import { Link } from 'react-router-dom';
import {Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import moment from 'moment';
import './usermain.css'
import { address, address_with_api } from '../settings';

Chart.register(CategoryScale);

const Usermain = () => {
    const intl = useIntl()
    const token = Cookies.get('_auth')
    const [loading,setLoading] = useState(true)
    const [counter,setCounter] = useState(0)
    const [price,setPrice] = useState(0)
    const [average, setAverage] = useState(0)
    const [KeyArray,setKeyArray] = useState([])
    const [ValArray,setValArray] = useState([])
    const [userData,setUserData] = useState([])
    const [tarif,setTarif] = useState('piece_tarif')
    const [user,setUser] = useState()
    const downloadFile =() => {
        let start = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
        let end = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        alert('Запрос на получение счёта принят. В течение 5 минут на вашу почту придёт письмо с файлом')
        fetch(address_with_api+'/download/',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Authorization': 'Bearer '+token,
            },
            body:'{"start_date":"'+start+' 00:00:00","end_date":"'+end+' 23:59:59"}'
        })
    }
    useEffect(()=>{
        fetch(address_with_api+'/user/info', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            setUser(result);
          }
          else alert("Неверно введённые данные")
        })
        let body={
            "id_mas":{"mas":[104]},
            "mode":"general",
            "date_start":moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            "date_end":moment().format('YYYY-MM-DD HH:mm:ss'),
            "elements":100
        }
        fetch(address+'/get/users/statistic', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(body)
          }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
                setKeyArray(Object.keys(resp[0]['World']))
                setValArray(Object.values(resp[0]['World']))
                setCounter(resp[1])
                setLoading(false)
          })
          fetch(address+'/get/users/researches', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
              },
            body:JSON.stringify(body)}).
                then(resp=>{if(resp.ok)return resp.json()}).
                    then(result=>{
                        let final = 7
                        let mas = []
                        result.filter(item=>{
                            moment(item.requestcstoretime).diff(moment(item.requesttime))>0&&
                            moment(item.requestcstoretime).diff(moment(item.requesttime))<400000
                            ?
                                mas.push(moment(item.requestcstoretime).diff(moment(item.requesttime))/1000).toFixed(1)
                                :
                                (parseInt((Math.random() * (20.0 - 10.0)+10.0).toFixed(1)))
                        })
                        if(mas.length!==0){
                            let sum = mas.reduce((a,b)=>(a+b));
                            final=(sum/(mas.length)).toFixed(1)
                        }
                        mas.length!==0?setAverage(final):setAverage(0)
                        setLoading(false)
                    })
      },[])
    const str = (txt) => {
        return (<div className='info-txt'>
            {txt}
        </div>)
    }
    return (
        <div className='user-main'>
            <div className='personal-info'>
                <div className='block-name'>
                    <div className='block-name-text'><FormattedMessage id="user_main_person_title"/></div>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>):(
                    <div className='block-content'>
                    {str(intl.formatMessage({id:'user_main_person_login'})+": "+ user.login)}
                    {str(intl.formatMessage({id:'user_main_person_email'})+": "+ user.email)}
                    {str(intl.formatMessage({id:'user_main_person_address'})+": "+ user.address)}
                    {str(intl.formatMessage({id:'user_main_person_date_creation'})+": "+ user.datetime_creation.split('T')[0].split('-').reverse()[0]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[1]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[2])}
                    <div className='info-txt'>
                        {intl.formatMessage({id:'user_main_person_status'})+": "}{user.is_active ? (localStorage.getItem('locale')=='ru-RU'?"активен":"active"):(localStorage.getItem('locale')=='ru-RU'?"не активен":"not active")}
                    </div>
                    {str(intl.formatMessage({id:'user_main_person_organization'})+": "+ user.organization)}
                </div>
                )}
            </div>
            <div className='count-info'>
                <div className='block-name'>
                    <div className='block-name-text'><FormattedMessage id="user_bill_title"/></div>
                </div>
                {loading?(
                    <div className='div-loading'>
                        <CircularProgress sx={{color:'white'}}/>
                    </div>
                ):(
                    <div className='block-content'>
                        {str(intl.formatMessage({id:'user_bill_tarif'})+": "+intl.formatMessage({id:tarif}))}
                        {str(intl.formatMessage({id:'user_bill_contract'})+": "+ user.contract)}
                        {str(intl.formatMessage({id:'user_bill_counter'})+": "+counter)}
                        {str(intl.formatMessage({id:'user_bill_price'})+": "+ (user.tarif_type=='piece'?price:'0')+" ₽")}
                        {user.tarif_type=='piece'&&str(intl.formatMessage({id:'user_bill_month_count'})+": " +price*counter+" ₽")}
                    </div>
                )}  
                <div className='count-download-button'>
                    {price!==0&&
                    <button onClick={()=>downloadFile()}>
                        <FormattedMessage id="user_bill_download_stat"/>
                    </button>}
                </div>
            </div>
            <div className='stat-info'>
                <div className='block-name'>
                    <Link to='/user/stat' className='block-name-text'><FormattedMessage id="user_main_stat_title"/></Link>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>):(
                <div className="stat-info-content">
                    <div className='stat-info-diagram'>
                        <Bar style={{background:'white',borderRadius:10,padding:10}}
                            data={{
                            labels: KeyArray.map((val)=>{return moment(val).format('DD.MM')}),
                            datasets: [
                                {
                                data: ValArray,
                                borderColor:'#000ffffff ',
                                borderWidth:0.7,
                                hoverBackgroundColor:'#006ba6',
                                backgroundColor:'#63c7ff',
                                }, 
                            ],
                            }}
                            options={{
                                interaction: {
                                    intersect: false,
                                    mode:'index'
                                },
                                scales: {
                                    yAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'studies'})
                                        },
                                    },
                                    xAxes: {
                                        title:{
                                            display:!(document.documentElement.scrollWidth<500),
                                            text:intl.formatMessage({id:'date'})
                                        },
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            
                                        }
                                    },
                                }
                            }}
                        />
                    </div>
                    <div className='stat-info-data'>
                        {str(intl.formatMessage({id:'user_main_stat_total'})+": "+counter)}
                        {str(intl.formatMessage({id:'user_main_stat_max'})+": "+ Math.max(...ValArray))}
                        {str(intl.formatMessage({id:'user_main_stat_min'})+": "+Math.min(...ValArray))}
                        <div className='info-txt'>
                            {intl.formatMessage({id:'user_main_stat_average'})+": "+average+" "}<FormattedMessage id='sek'/>
                        </div>
                        {user.tarif_type=='piece'&&str(intl.formatMessage({id:'user_bill_month_bill'})+": " +price*counter+" ₽")}
                    </div>
                </div>)}
            </div>
        </div>
    );
};

export default Usermain;