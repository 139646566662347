import React from 'react';
import './contact.css'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
const Contact = (props) => {
    return (
        <div className='contact-form' id={props.id}>
            <div className='contact-icon'>
                {props.title==='АДРЕС:'||props.title==='ADDRESS:'?(<RoomIcon sx={{ fontSize: 50 }}/>):(<br/>)} 
                {props.title==='ТЕЛЕФОН:'||props.title==='PHONE:'?(<PhoneAndroidIcon sx={{ fontSize: 50 }}/>):(<br/>)} 
                {props.title==='E-MAIL:'?(<EmailIcon sx={{ fontSize: 50 }}/>):(<br/>)}
            </div>
            <div className='contact-title'>
                {props.title}
            </div>
            <div className='contact-text'>
                {props.text}
            </div>
        </div>
    );
};

export default Contact;