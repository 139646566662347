import CircularProgress from '@mui/material/CircularProgress';
import {FormattedMessage,useIntl} from 'react-intl';
import { useState,useEffect } from 'react';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import '../../User/usermain.css'
import { address, address_with_api } from '../../settings';

Chart.register(CategoryScale);

const IndividualMain = () => {
    const intl = useIntl()
    const token = Cookies.get('_auth')
    const [loading,setLoading] = useState(true)
    const [changeData,setChangeData] = useState(false)
    const [changePass,setChangePass] = useState(false)
    const [user,setUser] = useState()
    const [newUser,setNewUser] = useState({})
    const [pass,setPass]= useState({
        old_password:'',
        new_password:'',
    })
    useEffect(()=>{
        fetch(address_with_api+'/user/info', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
          if(result!==false){
            setUser(result);
            setNewUser(result)
            setLoading(false);
          }
          else alert("Неверно введённые данные")
        })
      },[])
    const str = (txt) => {
        return (<div className='info-txt'>
            {txt}
        </div>)
    }
    const handlePass = (e) =>{
        setPass({...pass,[e.target.name]:e.target.value})
    }
    const handler = (e) =>{
        setNewUser({...newUser,[e.target.name]:e.target.value})
    }
    const EditData = () => {
        fetch(address+'/individual/data/update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'accept': 'application/json',
              'Access-Control-Allow-Origin':'*',
              'Authorization': 'Bearer '+token,
          },
            body:JSON.stringify(newUser)
          }).then(response=>response.json()).then(result=>{
            setUser({...user,
                ['full_name']:result.full_name,
                ['itn']:result.itn,
                ['organization']:result.organization,
                ['address']:result.address
            })
            setChangeData(false)
        })
    }
    const EditPass = () =>{
        let dflt = {
            old_password:'',
            new_password:'',
        }
        if(pass.new_password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_]).{8,}$/)==null){alert ('Пароль должен быть больше 8 символов и содержать:\nЗаглавные и строчные латинские буквы\nМинимум 1 цифру\nМинимум 1 специальный символ (!@#$%&*_{})');return 0;}
        if(pass.new_password!==document.getElementById('repeat').value){alert("Пароли не совпадают");return 0}
        fetch(address_with_api+'/individual/data/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:JSON.stringify(pass)
            }).then(response => {
                if (response.ok){
                    setPass(dflt);
                    setChangePass(false);
                    document.getElementById('repeat').value=''
                    alert('Пароль успешно обновлён')
                    return 0
                }
                else return 1
            }).then(result=>{
                if (result!==0){
                    if(result.status==401){alert ('Старый пароль неправильный');return 0}
                    if(result.status==422){alert ('Пароль должен быть больше 8 символов и содержать:\nЗаглавные и строчные латинские буквы\nМинимум 1 цифру\nМинимум 1 специальный символ (!@#$%&*_{})');return 0}
                    if(result.status==400){alert ('Старый и новый пароли не должны совпадать');return 0}
                }
            })
    }
    return (
        <div className='user-main'>
            {changeData&&
            <div className='finance-form-field'>
                <div className='finance-pay-form'>
                    <div className='finance-pay-form-title'>Редактирование данных</div>
                    <div className='finance-pay-form-data'>
                        <div className='finance-pay-form-field'>
                            <div>Фамилия Имя</div> 
                            <input type='text' id='updatefield' value = {newUser.full_name} name='full_name' onChange={handler}></input>
                        </div>
                        <div className='finance-pay-form-field'>
                            <div>Организация</div> 
                            <input type='text' id='updatefield' value = {newUser.organization} name='organization' onChange={handler}></input>
                        </div>
                        <div className='finance-pay-form-field'>
                            <div>Телефон</div> 
                            <input type='text' id='updatefield' value = {newUser.itn} name='itn' onChange={handler}></input >
                        </div>
                        <div className='finance-pay-form-field'>
                            <div>Адрес</div> 
                            <input type='text' id='updatefield' value = {newUser.address} name='address' onChange={handler}></input >
                        </div>
                        <div className='finance-pay-form-button'>
                            <button className='finance-pay-button' onClick={()=>EditData()}>Сохранить</button>
                            <button className='finance-pay-cancel-button' onClick={()=>setChangeData(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            </div>}
            {changePass&&
            <div className='finance-form-field'>
                <div className='finance-pay-form'>
                    <div className='finance-pay-form-title'>Смена пароля</div>
                    <div className='finance-pay-form-data'>
                        <div className='finance-pay-form-field'>
                            <div>Старый пароль</div> 
                            <input type='password' id='old' value = {pass.old_password} name='old_password' onChange={handlePass}></input>
                        </div>
                        <div className='finance-pay-form-field'>
                            <div>Новый пароль</div> 
                            <input type='password' id='new' value = {pass.new_password} name='new_password' onChange={handlePass}></input>
                        </div>
                        <div className='finance-pay-form-field'>
                            <div>Повторите новый пароль</div> 
                            <input type='password' id='repeat' name='repeat_new'></input >
                        </div>
                        <div className='finance-pay-form-button'>
                            <button className='finance-pay-button' onClick={()=>EditPass()}>Сохранить</button>
                            <button className='finance-pay-cancel-button' onClick={()=>setChangePass(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='individual-info'>
                <div className='block-name'>
                    <div className='block-name-text'><FormattedMessage id="user_main_person_title"/></div>
                </div>
                {loading?
                (<div className='div-loading'>
                    <CircularProgress sx={{color:'white'}}/>
                </div>):(
                    <div className='block-content'>
                        {str("E-mail"+": "+ user.email)}
                        {str(intl.formatMessage({id:'user_main_person_date_creation'})+": "+ user.datetime_creation.split('T')[0].split('-').reverse()[0]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[1]+"."+user.datetime_creation.split('T')[0].split('-').reverse()[2])}
                        {str("Организация: "+ user.organization)}
                        {str("Фамилия Имя: "+ user.full_name)}
                        {str("Адрес: "+ (user.address?user.address:'Нет'))}
                        {str("Текущий тариф: "+ (user.tarif_type&&user.tarif_type==='pack'?'Пакетный':'Поштучный'))}
                        {str("Телефон: "+ (user.itn?user.itn:'Нет'))}
                        <div className='info-txt'>
                            {intl.formatMessage({id:'user_main_person_status'})+": "}{user.is_active ? (localStorage.getItem('locale')=='ru-RU'?"активен":"active"):(localStorage.getItem('locale')=='ru-RU'?"не активен":"not active")}
                        </div>
                        <div className='info-button'>
                            <button onClick={()=>setChangeData(true)}>Редактироват данные </button>
                        </div>
                        <div className='info-button'>
                            <button onClick={()=>setChangePass(true)}>Сменить пароль</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default IndividualMain;