import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CircularProgress from '@mui/material/CircularProgress';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Pagination } from '@mui/material';
import PrismaZoom from 'react-prismazoom';
import { Line } from 'react-chartjs-2'
import Cookies from 'js-cookie'
import moment from 'moment';
import ErrorsTable from './ErrorsTable';
import { address, address_with_api } from '../settings';


const AdminErrors = () => {
    const [bigDiagramm,setBigDiagramm] = useState(false)
    const [podMas,setPodMas]=useState([])
    const [page,setPage]=useState(1)
    const [selectedUsers,setSelectedUsers] = useState({})
    const [pages,setPages] =useState(1)
    const [elements,setElements] = useState(100)
    const [researchBackup,setResearchBackup] = useState([])
    const [KeyArray,setKeyArray] = useState([])
    const token = Cookies.get('_auth')
    const [activeUsers, setActiveUsers] = useState([])
    const [inActiveUsers, setInActiveUsers] = useState([])
    const [researches,setResearches] = useState([])
    const [users,setUsers] = useState([])
    const [backupUsers,setBackupUsers] = useState([])
    const [imageLoading,setImageLoading] = useState(false)
    const [tableLoading,setTableLoading] = useState(true)
    const [tableHidden,setTableHidden] = useState(true)
    const [counter,setCounter] = useState(0)
    const [url,setUrl]=useState('')
    const [mas,setMas] = useState([])
    const colors = ['#C71585','#00FFFF','#00FF00','#FF4500','#DC143C','#FF00FF','#4B0082','#0000CD','#2F4F4F','#708090','#7B68EE','#4169E1','#00BFFF','#5F9EA0','#40E0D0','#30BA8F','#FB607F','#1DACD6','#FADBC8','#753313','#3D642D','#CC7722','#EE204D',"#A5694F","#2A52BE","#76FF7A","#310062","#270A1F","#801818","#FF43A4","#FF8C69","#1CD3A2","#CD00CD","#E28B00","#F7F21A","#8C4743","#1A4876","#4CBB17","#022027","#FFE5B4","#6DAE81"]
    const [loading,setLoading] = useState(true)
    const [graphLoading,setGraphLoading] = useState(true)
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [bigTable,setBigTable] = useState(false)
    useEffect(()=>{
        document.getElementById('end').value= moment().format('YYYY-MM-DD')
        document.getElementById('start').value= moment().date(1).format('YYYY-MM-DD')
        let obj = {}
        let date = moment().format("YYYY-MM-DD HH:mm:ss")
        fetch(address+'/users/for/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer ' + token,
        },
          body:'{"date":"'+date+'"}'
        }).then(resp=>{
            if(resp.ok) return resp.json()
            else return false
        }).then(result=>{
            if(result!==false){
            setUsers(result)
            setBackupUsers(result)
            result.map((item)=>obj[`${item.id}`]=false)
            setSelectedUsers(obj)
            setLoading(false)
            setGraphLoading(false)
          }
            else alert("Неверно введённые данные")
        checkActiveUsers()
        setInterval(()=>{
            checkActiveUsers()
        },120000)
        })
    },[])
    const handler = () =>{
        let masiv = []
        masiv = [...researchBackup].filter((item)=>item.studyinstanceuid.indexOf(document.getElementById('research').value)!=-1)
        setResearches(masiv)
    }
    const [sort,setSort] = useState({
        name:'datetime',
        direction:false  //True = на возрастание; False = на убывание 
    })
    const organizationHandler = (e) =>{
        let masiv = []
        masiv = [...backupUsers].filter((item)=>item.organization.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1||item.full_name.toLowerCase().indexOf(e.target.value.toLowerCase().trim())!=-1)
        setUsers(masiv)
    }
    const clearOrganization = () =>{
        document.getElementById('organization').value=''
        setUsers(backupUsers)
    }
    const checkActiveUsers = () =>{
        fetch(address_with_api+'/get/active/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
            }}).then((result)=>result.json()).then(res=>{
                setActiveUsers(res.active_users)
                setInActiveUsers(res.inactive_users)
            })
    }
    const sel = () =>{
        var val = document.getElementById('selectall').checked
        let obj ={}
        Object.keys(selectedUsers).map(item=>parseInt(item)).map(item=>obj[item]=val)
        setSelectedUsers(obj)
    }
    const hideTable = () =>{
        setTableHidden(true)
        setBigTable(false)
    }
    const handlePage = (event, value) => {
        setPage(value);
        setResearches(podMas[value-1])
    }
    const handleResearches = () =>{
        let masiv = []
        masiv = [...researchBackup].filter((item)=>item.studyinstanceuid.indexOf(document.getElementById('research').value)!=-1)
        setResearches(masiv)
    }
    const handleSelect = () => {//Обработчик изменений полей для ввода даты
        if (moment(document.getElementById('start').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('isoWeek'),moment().endOf('isoWeek')))document.getElementById('ad-select').value='current_week'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('month').add(-1,'day'),moment().endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('month'),moment().endOf('month')))document.getElementById('ad-select').value='current_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'month').startOf('month').add(-1,'day'),moment().add(-1,'month').endOf('month'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')))document.getElementById('ad-select').value='previous_month'
        else if (moment(document.getElementById('start').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().startOf('quarter'),moment().endOf('quarter')))document.getElementById('ad-select').value='current_quarter'
        else if (moment(document.getElementById('start').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter'))&&moment(document.getElementById('end').value).isBetween(moment().add(-1,'quarter').startOf('quarter'),moment().add(-1,'quarter').endOf('quarter')))document.getElementById('ad-select').value='previous_quarter'
        else document.getElementById('ad-select').value='random'
    }
    const nextPage =() => {
        setTableLoading(true)
        var id_mas = Object.values(document.getElementsByName('input')).filter((item)=>item.checked).map(item=>parseInt(item.id))
        let number = parseInt(document.getElementById("elements").value)
        if(id_mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":id_mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":(number*(page+1))
        }
        fetch(address+'/get/users/errors', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <result.length/number; i++){
                            podmas[i]=result.slice((i*number), (i*number) + number);
                        }
                        setPage(podmas.length)
                        setPodMas(podmas)
                        setResearches(podmas[podmas.length-1])
                        setTableLoading(false)
                    })
    }
    const handleChange = () => { // Обработчик события изменения селектора выбора периода времени
        switch(document.getElementById('ad-select').value){
            case 'default':
                break;
            case 'current_week':
                document.getElementById('start').value=moment().clone().startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().clone().endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'last_week':
                document.getElementById('start').value=moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                break;
            case 'current_month':
                document.getElementById('start').value=moment().startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('month').format('YYYY-MM-DD');
                break;
            case 'previous_month':
                document.getElementById('start').value=moment().add(-1,'month').startOf('month').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'month').endOf('month').format('YYYY-MM-DD');
                break;
            case 'current_quarter':
                document.getElementById('start').value=moment().startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'previous_quarter':
                document.getElementById('start').value=moment().add(-1,'quarter').startOf('quarter').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'quarter').endOf('quarter').format('YYYY-MM-DD');
                break;
            case 'current_year':
                document.getElementById('start').value=moment().startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().endOf('year').format('YYYY-MM-DD');
                break;
            case 'previous_year':
                document.getElementById('start').value=moment().add(-1,'year').startOf('year').format('YYYY-MM-DD');
                document.getElementById('end').value=moment().add(-1,'year').endOf('year').format('YYYY-MM-DD');
                break;
            default:break
        }
        sendData()
    }
    const handleElements =() => {
        setTableLoading(true)
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))
        let number = parseInt(document.getElementById("elements").value)
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setElements(number)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":number*page
        }
        fetch(address+'/get/users/errors', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(Math.ceil(result.length/number))
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/number); i++){
                            podmas.push(result.slice((i*number), (i*number) + number));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                        setPage(1)
                    })
    }
    const sortTable = (field) =>{
        let masiv = []
        if(sort.name===field){
            if (sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
                if(field=='status')masiv = [...researches].sort((a,b)=>b['status']-a['status'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
                if(field=='error_message')masiv = [...researches].sort((a,b)=>a['error_message'].localeCompare(b['error_message']))
                setSort({...sort,['name']:field,['direction']:false})
                setResearches(masiv)
            }
            if(!sort.direction){
                if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(a['datetime'])-moment(b['datetime']))
                if(field=='status')masiv = [...researches].sort((a,b)=>a['status']-b['status'])
                if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>b['studyinstanceuid'].localeCompare(a['studyinstanceuid']))
                if(field=='organization')masiv = [...researches].sort((a,b)=>b['organization'].localeCompare(a['organization']))
                if(field=='error_message')masiv = [...researches].sort((a,b)=>b['error_message'].localeCompare(a['error_message']))
                setResearches(masiv)
                setSort({...sort,['name']:field,['direction']:true})
            }
        }
        else{
            if(field=='datetime')masiv = [...researches].sort((a,b)=>moment(b['datetime'])-moment(a['datetime']))
            if(field=='status')masiv = [...researches].sort((a,b)=>b['prob']-a['status'])
            if(field=='studyinstanceuid')masiv = [...researches].sort((a,b)=>a['studyinstanceuid'].localeCompare(b['studyinstanceuid']))
            if(field=='organization')masiv = [...researches].sort((a,b)=>a['organization'].localeCompare(b['organization']))
            if(field=='error_message')masiv = [...researches].sort((a,b)=>a['error_message'].localeCompare(b['error_message']))
            setSort({...sort,['name']:field,['direction']:false})
            setResearches(masiv)
        }
    }
    const sendData = () => {//отправка данных о периоде и получение статистики по выбранным пользователям
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        setGraphLoading(true)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":elements*page
        }
        fetch(address+'/get/users/errors/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            var massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension: 0,hoverRadius: 5,hoverBackgroundColor: 'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setPage(1)
            setMas(massive)
            setCounter(resp[1])
            setGraphLoading(false)
            setLoading(false)
        })
        setTableHidden(false)
        setTableLoading(true)
        fetch(address+'/get/users/errors', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
                },
              body:JSON.stringify(body)})
                .then(resp=>{if(resp.ok)return resp.json()})
                    .then(result=>{
                        setResearchBackup(result)
                        setPages(1)
                        let podmas=[]
                        for (let i = 0; i <Math.ceil(result.length/elements); i++){
                            podmas.push(result.slice((i*elements), (i*elements) + elements));
                        }
                        setPodMas(podmas)
                        setResearches(podmas[0])
                        setTableLoading(false)
                    })
    }
    const getStatistic =() =>{
        let mas = Object.keys(selectedUsers).filter((item)=>selectedUsers[item]).map((item)=>parseInt(item))
        if(mas.length==0){alert('Выберите пользователей'); document.getElementById('ad-select').value='default';setGraphLoading(false);return 0}
        var modif = document.getElementById('check').checked?"comparative":"general"
        console.log(modif)
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        console.log('hello')
        setGraphLoading(true)
        let body={
            "id_mas":{"mas":mas},
            "mode":modif,
            "date_start":start+' 00:00:00',
            "date_end":end+' 23:59:59',
            "elements":elements*page
        }
        fetch(address+'/get/users/errors/statistic', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization': 'Bearer '+token,
        },
          body:JSON.stringify(body)
        }).then(res=>{if(res.ok) return res.json()}).then(resp=>{
            var massive=[]
            setKeyArray(Object.keys(Object.values(resp[0])[0]))
            let obj={data: [],borderColor: ['blue',],borderWidth: 3,tension: 0,hoverRadius: 5,hoverBackgroundColor: 'blue'}
            let login = Object.keys(resp[0])
            let values = Object.values(resp[0]).map((item=>Object.values(item)))
            if(!document.getElementById('check').checked)
            {
                obj.hoverBackgroundColor=colors[1]
                obj.label='Исследований'
                obj.data=values[0]
                obj.borderColor=[colors[1]]
                massive.push(obj)
            }
            else login.map((item,index)=>{
                obj.hoverBackgroundColor=colors[index]
                obj.label=item
                obj.data=values[index]
                obj.borderColor=[colors[index]]
                massive.push(obj)
                obj={label: '',data: [],borderColor: ['blue',],borderWidth: 3,tension:0,hoverRadius:5,hoverBackgroundColor:'blue',}
            })
            setCounter(resp[1])
            setGraphLoading(false)
            setMas(massive)
        })
    }
    const getImage = (id) => {
        setIsImgEditorShown(true)
        setImageLoading(true)
        document.getElementById(parseInt(id)).style.backgroundColor='lightskyblue'
        fetch(address_with_api+'/get/error/image', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'application/json',
                'Conteny-type':'image/png'
            },
            body:'{"id":'+id+'}'}).then(resp=>{if(resp.ok) return resp; else return false}).then((response) => {
                if(response!=false) {const reader = response.body.getReader();
                return new ReadableStream({
                  start(controller) {
                    return pump();
                    function pump() {
                      return reader.read().then(({ done, value }) => {
                        if (done) {
                          controller.close();
                          return;
                        }
                        controller.enqueue(value);
                        return pump();
                      });
                    }
                  }
                })}
                else {
                    setImageLoading(false);
                    return 0}
              }).then((stream) => new Response(stream)).then((response) => response.blob()).then((blob) => URL.createObjectURL(blob))
              .then((url) => {
                setUrl(url)
                setImageLoading(false)
                document.getElementById('image').src = url;
            });
    }
    return (
        <div className='admin-stat'>
            {isImgEditorShown && (
                <div className='edit-div' id='editWindow'>
                    <div className='admin-edit-header'>
                        <div className='admin-edit-header-btn' onClick={()=>setIsImgEditorShown(false)}>
                            <a className='edit-header-link'><CloseIcon sx={{fontSize:50,color:"white"}}/></a>
                        </div>
                    </div>
                    {imageLoading?
                        (<div className='div-loading' id='editimage'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                        ):(
                        <>
                            <PrismaZoom className='prizm' animDuration='0' style={{width:'auto',height:'auto',maxWidth:'100%',maxHeight:'93%',boxSizing:'border-box',display:'flex',justifyContent:'center',alignItem:'center'}}>
                                <img id='image' src={url}/>
                            </PrismaZoom> 
                        </>
                        )}
                </div>
            )}
            <div className='admin-stat-table'>
                <div className='admin-stat-table-title'>
                    <div className='admin-stat-table-title-text'>Пользователи</div>
                </div>
                <div className='admin-stat-table-data'>
                    {loading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(
                        <div className='stat-table-data'>
                            <table className='ad-stat-table'>
                                <thead className='ad-stat-thead' >
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th'><input type='checkbox'  onChange={()=>sel()} id='selectall'/></th>
                                        <th className='stat-tr-th' id='stat-tr-th'><input id='organization' onBlur={()=>document.getElementById('organization').placeholder='Организация'} onFocus={()=>document.getElementById('organization').placeholder=''} type='text' placeholder='Организация' onChange={organizationHandler}/><CloseIcon className='close-icon' title='Очистить поле' sx={{color:'white',fontSize:'14'}} onClick={()=>clearOrganization()}/></th>
                                        <th className='stat-tr-th'>Логин</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody'>
                                    {users.length===0&&(
                                        <tr className='ad-stat-tr'>
                                            <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                        </tr>
                                    )}
                                    {users.map((item)=>
                                        <tr key={item.id} className='ad-stat-tr' onClick={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})}>
                                            <td className='stat-tr-td'><input id = {item.id} checked={selectedUsers[`${item.id}`]} onChange={()=>setSelectedUsers({...selectedUsers,[item.id]:!selectedUsers[item.id]})} type='checkbox' name='input'/></td>
                                            <td className='stat-tr-td'>
                                                <div className="stat-tr-td-div">
                                                <div className='stat-status' title={activeUsers.indexOf(item.id)>=0?"Пользователь онлайн":inActiveUsers.indexOf(item.id)>=0?"У пользователя нет доступных исследований":""}>
                                                        {activeUsers.indexOf(item.id)===-1?
                                                        (inActiveUsers.indexOf(item.id)===-1?<div></div>:
                                                            <CircleIcon sx={{color:'#feff00'}} fontSize="10"/>):(
                                                            <CircleIcon sx={{color:'#00FEFF'}} fontSize="10"/>
                                                        )}
                                                    </div>
                                                    <div className='stat-tr-td-info'>
                                                        {item.organization}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='stat-tr-td'>{item.login}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className='ad-stat-select'>
                    <select id='ad-select' onChange={()=>handleChange()}>
                        <option value='default'>Выбрать период</option>
                        <option value='current_week'>Текущая неделя </option>
                        <option value='last_week'>Прошлая неделя </option>
                        <option value='current_month'>Текущий месяц </option>
                        <option value='previous_month'>Прошлый месяц </option>
                        <option value='current_quarter'>Текущий квартал </option>
                        <option value='previous_quarter'>Предыдущий квартал</option>
                        <option value='current_year'>Текущий год </option>
                        <option value='previous_year'>Прошлый год </option>
                        <option value='random'>Произвольный период </option>
                    </select>
                </div>
                <div className='admin-stat-table-date'>
                    <input className="admin-stat-table-date-input" id = 'start' type="date" onChange={()=>handleSelect()}/>
                    <input className="admin-stat-table-date-input" id = 'end' type="date" onChange={()=>handleSelect()}/>
                </div>
                <div className='admin-stat-table-date-check'>
                    Общая
                    <label className="switch">
                        <input type="checkbox" id="check" onClick={()=>{if(researches.length!==0)getStatistic(); else sendData()}}/>
                        <span className="slider round"></span>
                    </label>
                    Сравнительная
                </div>
                <div className='admin-stat-table-date-send'>
                    <button onClick={()=>sendData()}>Отобразить данные</button>
                </div>
            </div>
            <div className={bigDiagramm?"admin-stat-diagram-big":"admin-stat-diagram"}>
                <div className='admin-stat-diagram-title'>
                    <div className='admin-stat-diagram-title-container-1'>
                        <div className='admin-stat-diagram-title-text'>
                            Статистика
                        </div>
                    </div>
                    <div className='admin-stat-diagram-title-container-2'>
                        {graphLoading?(
                            <div className='div-loading'>
                            </div>
                        ):(
                            <div className='admin-stat-diagram-title-info'>
                                Всего исследований: {counter}
                            </div>
                        )}
                    </div>
                    <div className='big-diagramm'>
                        {bigDiagramm?
                        (
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(false)}>
                                <CloseFullscreenIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        ):(
                            <div className='big-diagramm-icon' onClick={()=>setBigDiagramm(true)}>
                                <OpenInFullIcon fontSize='medium' sx={{color:'white'}}/>
                            </div>
                        )}
                    </div>
                </div>
                <div className='admin-stat-diagram-data'>
                    {graphLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):
                    (<Line style={{background:'white',borderRadius:10,padding:10}}
                        data={{
                        labels:moment(KeyArray[0]).year()===moment(KeyArray[KeyArray.length]).year()? KeyArray.map((val)=>{return moment(val).format('DD.MM')}):KeyArray.map((val)=>{return moment(val).format('DD.MM.YY')}),
                        datasets:mas
                        }}
                        options= {{
                            pointRadius: 1,
                            pointHoverRadius: 3,
                            responsive: true,
                            interaction: {
                                mode: document.getElementById('check').checked?'nearest':'index',
                                intersect: false,
                            },
                            scales: {
                                y: {
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                },
                                title:{
                                    display:!(document.documentElement.scrollWidth<500),
                                    text:'Исследования'
                                },
                                display: true,
                                beginAtZero: true,
                                precision: 0,
                                },
                                x:{
                                gridLines: {
                                    display: !(document.documentElement.scrollWidth<500)
                                    },
                                title:{
                                display:!(document.documentElement.scrollWidth<500),
                                text:'Дата'},
                            },
                            },
                            }}/>)}
                </div>
            </div>
            <div className={bigTable?'admin-research-table-active':'admin-research-table'} hidden={tableHidden} id='researchTable'>
                <div className={bigTable?'admin-research-table-close-active':'admin-research-table-close'}>
                    <div className='pagination-block'>
                        <div className='select-count'>
                            Отображать:
                            <select id="elements" onChange={handleElements}>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={400}>400</option>
                                <option value={800}>800</option>
                            </select>
                        </div>
                        <div className='serach-field'>
                            <SearchIcon/>
                            <input type='text' id = 'research' placeholder='Введите StudyUID' onChange={handleResearches}/>
                        </div>
                        <Pagination count={pages} siblingCount={0} page={page} onChange={handlePage} hideNextButton={page==pages} defaultPage={1}/>
                            {page==pages&&
                                <div className='pag-div' onClick={()=>nextPage()}>
                                    Ещё
                                </div>
                            }
                    </div>
                    {bigTable?
                        (<button id='researchTableBtn' onClick={()=>setBigTable(false)}>Свернуть табицу</button>):
                        (<button id='researchTableBtn' onClick={()=>setBigTable(true)}>Развернуть таблицу</button>)
                    }
                    <button onClick={()=>hideTable()}>Закрыть</button>
                </div>
                <div className={bigTable?'admin-research-table-data-active':'admin-research-table-data'}>
                    {tableLoading?(
                        <div className='div-loading'>
                            <CircularProgress sx={{color:'white'}}/>
                        </div>
                    ):(<ErrorsTable researches={researches} sortTable={sortTable} getImage={getImage} handler={handler} sort={sort} setSort={setSort}/>)
                    }
                </div>
            </div>
        </div>
    );
}

export default AdminErrors;