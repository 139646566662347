import React from 'react';
import './prize.css'
const Prize = (props) => {
    return (
        <div className='prize' id={props.id}>
            <div className='prize-img'>
                <img src={require(`./img/${props.img}`)} alt=""/>
            </div>
            <div className='prize-text'>
                <div className='text-end'>
                    {props.text}
                </div>
            </div>
        </div>
    );
};

export default Prize;