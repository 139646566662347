import React from 'react';
import 'react-quill/dist/quill.snow.css';
import './adminnews.css'
import Newsfield from './Newsfield';

const Adminnews = () => {
    return (
        <div className='admin-news'>
            <div className='add-news'>
                <button onClick={()=>{window.location.assign("http://"+window.location.host+"/admin/adminnews/new")}}>Добавить новость</button>
            </div>
            <Newsfield id="1" img='news-1.2.png' text='В период 9-10 февраля 2022, проходила VI Всероссийская конференции по Искусственному интеллекту в здравоохранении и системам поддержки принятия врачебных решений ITM-AI. Конференция «ITM-AI» является ежегодной и проводится в шестой раз. Ранее проходила под названием «MosCAI» в составе Международного конгресса «Информационные технологии в медицине» (ИТМ).В период 9-10 февраля 2022, проходила VI Всероссийская конференции по Искусственному интеллекту в здравоохранении и системам поддержки принятия врачебных решений ITM-AI. Конференция «ITM-AI» является ежегодной и проводится в шестой раз. Ранее проходила под названием «MosCAI» в составе Международного конгресса «Информационные технологии в медицине» (ИТМ).'/>
            <Newsfield id="2" img='news-2.png' text='В период 9-10 февраля 2022, проходила VI Всероссийская конференции по Искусственному интеллекту в здравоохранении и системам поддержки принятия врачебных решений ITM-AI. Конференция «ITM-AI» является ежегодной и проводится в шестой раз. Ранее проходила под названием «MosCAI» в составе Международного конгресса «Информационные технологии в медицине» (ИТМ).'/>
            <Newsfield id="3" img='news-3.png' text='В период 9-10 февраля 2022, проходила VI Всероссийская конференции по Искусственному интеллекту в здравоохранении и системам поддержки принятия врачебных решений ITM-AI. Конференция «ITM-AI» является ежегодной и проводится в шестой раз. Ранее проходила под названием «MosCAI» в составе Международного конгресса «Информационные технологии в медицине» (ИТМ).'/>
            <Newsfield id="4" img='news-3.png' text='В период 9-10 февраля 2022, проходила VI Всероссийская конференции по Искусственному интеллекту в здравоохранении и системам поддержки принятия врачебных решений ITM-AI. Конференция «ITM-AI» является ежегодной и проводится в шестой раз. Ранее проходила под названием «MosCAI» в составе Международного конгресса «Информационные технологии в медицине» (ИТМ).'/>
        </div>
    );
};

export default Adminnews;