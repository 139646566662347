import { HashLink as Lnk } from 'react-router-hash-link';
import {FormattedMessage,useIntl} from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';

import video from '../../video/video.mp4'
import "../../styles/news.css"

const News = () => {
    const intl = useIntl()
    const transreload = (locale) => {
        localStorage.setItem('locale',locale)
        window.location.reload()
    }
    const stroka=(text)=>{
        return(
            <div className='new-cont-text'>
                {text}
            </div>
        )
    }
    return (
        <div className='new-content'>
            <div className='news-page-header'>
                <div className='main-header-head'>
                    {localStorage.getItem('locale')=='ru-RU'?
                        (
                        <div className='main-header-language' onClick={()=>transreload('en-US')}>
                            <img className='lang-img' src={require('../../images/lang-en.png')} alt=""/>
                        </div>
                        ):(
                        <div className='main-header-language' onClick={()=>transreload('ru-RU')}>
                            <img className='lang-img' src={require('../../images/lang-ru.png')} alt=""/>
                        </div>)}
                    <div className='main-header-auth'><Link to='/login' className='auth-button'><button className='auth-button'><FormattedMessage id="main_auth_btn"/></button></Link></div>
                </div>
                <div className='main-header-nav'>
                    <div className='main-header-link'>
                        <Lnk className='link' to="/"><FormattedMessage id="allnews_link_main"/></Lnk>
                        <Lnk className='link' to="/news"><FormattedMessage id="main_link_news"/></Lnk>
                        <Lnk className='link' to="/about"><FormattedMessage id="main_link_about"/></Lnk>
                        <Lnk className='link' to="/main/#test"><FormattedMessage id="main_link_test"/></Lnk>
                        {/* <Lnk className='link' to="/main/#partner"><FormattedMessage id="main_link_partner"/></Lnk> */}
                        <Lnk className='link' to="/main/#contacts"><FormattedMessage id="main_link_contacts"/></Lnk>
                    </div>
                </div>
            </div>
            <div className='new-cont'>
                <div className='new-cont-content'>
                    <div className='new-cont-title'><FormattedMessage id="news_4_title"/></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={require('../../images/news/news-4.png')}></img></div>
                    {stroka(intl.formatMessage({id:'news_4_text_1'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_2'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_3'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_4'}))}
                    <div className='new-cont-article'><FormattedMessage id="news_4_text_5"/></div>
                    {stroka(intl.formatMessage({id:'news_4_text_6'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_7'}))}
                    <div className='new-cont-article'><FormattedMessage id="news_4_text_8"/></div>
                    {stroka(intl.formatMessage({id:'news_4_text_9'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_10'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_11'}))}
                    <div className='new-cont-article'><FormattedMessage id="news_4_text_12"/></div>
                    <div className='new-cont-img-2'><img  id='img-4' alt="" src={require('../../images/news/news-4.2.png')}></img></div> 
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_13"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_14'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_15'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_16'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_17'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_18'}))}
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_19"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_20'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_21'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_22'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_23'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_24'}))}
                    <div className='new-video'>
                        <video controls
                            src={video}>
                        </video>
                    </div>
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_25"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_26'}))}
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_27"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_28'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_29'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_30'}))}
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_31"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_32'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_33'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_34'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_35'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_36'}))}
                    <div className='new-cont-subtitle'><b><FormattedMessage id="news_4_text_377"/></b></div>
                    {stroka(intl.formatMessage({id:'news_4_text_37'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_38'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_39'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_40'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_41'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_42'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_43'}))}
                    {stroka(intl.formatMessage({id:'news_4_text_44'}))}
                    {stroka(" ")}
                </div>
                {(document.documentElement.scrollWidth>500)?(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-social'>
                                <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                            </div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                ):(
                    <div className='new-footer'>
                        <div className='new-footer-content'>
                            <div className='new-social'>
                                    <a href='https://vk.com/club214630195' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/vk.png')} alt=""/></a>
                                    <a href='https://t.me/FtizisBioMed' target="_blank" rel="noreferrer"><img className='soc-img' src = {require('../subcomponents/img/tel.png')} alt=""/></a>
                                </div>
                            <div className='new-ftizis-info'><FormattedMessage id="main_ftizisbiomed"/></div>
                            <div className='new-vector-info'><FormattedMessage id="main_vector"/></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default News;