import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { publicRoutes } from '../Routes';
import './AppRouter.css'
import { RequireAuth } from 'react-auth-kit'
import Admin from "../pages/Admin"
import User from "../pages/User"
import {useAuthUser} from 'react-auth-kit';
import Individual from '../pages/Individual';


const AppRouter = () => {
    return (
        <div className='container'>
            <Routes>
                {publicRoutes.map(({path,component},key)=><Route exact path={path} element={component()} key={key}/>)}
                <Route path='admin/*'element={
                    <RequireAuth loginPath={'/login'}>
                        <Admin/>
                    </RequireAuth>} />
                <Route path='user/*'element={
                    <RequireAuth loginPath={'/login'}>
                        <User/>
                    </RequireAuth>} />
                <Route path='individual/*'element={
                    <RequireAuth loginPath={'/login'}>
                        <Individual/>
                    </RequireAuth>}/>
            </Routes>
        </div>
    );
};

export default AppRouter;